import { FC } from 'react';
import { Button } from '@mantine/core';

import { useKeyPersonsFill } from './KeyPersonsFill.hooks';

export const KeyPersonsFill: FC = () => {
  const { fillRegions, fillKeyPersons, isLoading } = useKeyPersonsFill();

  return (
    <div>
      <Button onClick={fillRegions}>Заполнить справочник регионов</Button>
      <Button onClick={fillKeyPersons} disabled={isLoading}>
        Заполнить справочник ключевых лиц
      </Button>
    </div>
  );
};

import { AdditionalFieldMeta, DictionaryItemsFilter } from '@prosv/core/types/bff/dictionary';
import { CustomFields } from '@prosv/core/types/bff/dictionary/custom';

import {
  CUSTOM_FIELD_PREFIX,
  DEFAULT_CONFIG,
  DICTIONARIES_CONFIGS,
} from './DictionaryItems.constants';
import { DictionaryConfig } from './DictionaryItems.types';
import { DictionaryItemsFilterValue } from './DictionaryItemsFilter';

export const isConfiguredDictionaryCode = (
  code: unknown,
): code is keyof typeof DICTIONARIES_CONFIGS =>
  typeof code === 'string' && Object.keys(DICTIONARIES_CONFIGS).includes(code);

export const getConfigByCode = <Dict extends string = string>(
  code: unknown,
): Required<DictionaryConfig<Dict>> =>
  (isConfiguredDictionaryCode(code) ? DICTIONARIES_CONFIGS[code] : DEFAULT_CONFIG) as Required<
    DictionaryConfig<Dict>
  >;

export const getRequestFilter = <Dict extends string = string>(
  { query, isActive, ...rest }: DictionaryItemsFilterValue<Dict>,
  metas: AdditionalFieldMeta[] = [],
): DictionaryItemsFilter<Dict> => {
  type Fields = CustomFields<Dict>;
  type Field = keyof CustomFields<Dict>;

  const res: DictionaryItemsFilter<Dict> = {};

  if (query && !Array.isArray(query)) {
    res.query = query;
  }
  if (isActive) {
    res.isActive = isActive === 'true';
  }

  Object.entries(rest).forEach(([field, value]) => {
    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }

    const code = field.replace(CUSTOM_FIELD_PREFIX, '') as Field;
    const meta = metas.find((item) => item.code === code);

    if (!meta) {
      return;
    }

    switch (meta.type) {
      case 'bool':
        value = value === 'true';
        break;
    }

    res.fields = res.fields ?? [];
    res.fields?.push({
      code,
      values: Array.isArray(value) ? value : [value as Fields[Field]],
    });
  });

  return res;
};

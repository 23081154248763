import { FC, useCallback, useEffect } from 'react';
import { Box, Button, LoadingOverlay, ScrollArea, Tabs } from '@mantine/core';

import { getFlatDataStructure } from '@/utils/getTransformMenuItems';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { ModalConfirm } from '@/ui/components/ModalConfirm';
import { Page } from '@/ui/components/Page';
import PageBody from '@/ui/components/Page/components/PageBody/PageBody';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { AddOrEditItem } from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem';
import { EAddNewItemConfirmationTexts } from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem.constants';

import { MenuContextEnum, menuName, MenuTypeEnum } from './MenuContainer.constants';
import { useMenuHandlers } from './MenuContainer.handlers';
import { useMenuState } from './MenuContainer.state';
import { MenuTabSite } from './MenuTabSite';

import {
  fetchGetMenuItemsContextAction,
  fetchGetMobileMenuItemsContextAction,
  setAddMenuItemsModalOpened,
  setDeleteMenuItemId,
  setMenuContext,
  setMenuType,
  setResetMenuItems,
} from '@/store/slices/menu/menuItems';
import { setTypeMenu } from '@/store/slices/menu/menuTypes';
import { MobileMenuTabSite } from '@/ui/containers/MenuContainer/MobileMenuTabSite';
import { AddOrEditMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem';
import { isTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.helpers';

export const MenuContainer: FC = () => {
  const dispatch = useAppDispatch();
  const {
    canUpdate,
    menuCode,
    menuAddItemsModal,
    deleteMenuItemId,
    anotherContextItems,
    menuItems,
    mobileMenuItems,
    editMenuItem,
    menuContext,
    parentId,
    currentTypeMenu,
    isFetching,
    isUpdating,
    breadcrumbs,
    proceed,
    reset,
    state,
    currentParent,
  } = useMenuState();
  const isMobileMenu = currentTypeMenu === MenuTypeEnum.MOBILE_MENU;

  const {
    onCreateNewMenuItem,
    onCreateNewMobileMenuItem,
    onEditMenuItemHandler,
    onEditMobileMenuItem,
    onDeleteHandler,
    onSaveHandler,
  } = useMenuHandlers({
    dispatch,
    menuItems,
    mobileMenuItems,
    currentTypeMenu,
    menuContext,
    anotherContextItems,
    deleteMenuItemId,
  });

  const onChangeTab = (context: MenuContextEnum) => dispatch(setMenuContext(context));
  const onConfirmLeave = useCallback(() => proceed && proceed(), [proceed]);
  const onCancel = useCallback(() => reset && reset(), [reset]);
  const deleteItemText = isMobileMenu ? EAddNewItemConfirmationTexts.REMOVE : EAddNewItemConfirmationTexts.REMOVE_FROM_ALL_SITES;

  useEffect(() => {
    if (!currentTypeMenu) {
      // navigate('/menu');
    }
    return () => {
      dispatch(setResetMenuItems());
    };
  }, [currentTypeMenu, dispatch]);

  useEffect(() => {
    if (currentTypeMenu) {
      if (isMobileMenu) {
        dispatch(fetchGetMobileMenuItemsContextAction());
      } else {
        dispatch(fetchGetMenuItemsContextAction({ menuContext, menuType: currentTypeMenu }));
      }
    } else {
      dispatch(setMenuType(menuCode));
      dispatch(setTypeMenu({ type: menuCode }));
    }
  }, [currentTypeMenu, dispatch, isMobileMenu, menuCode, menuContext]);

  const addModal = isMobileMenu ? (
    <AddOrEditMobileItem
      parentId={parentId}
      menuItems={mobileMenuItems}
      isOpened={menuAddItemsModal}
      onChange={onCreateNewMobileMenuItem}
      onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
    />
  ) : (
    <AddOrEditItem
      parentId={parentId}
      visibilityParent={currentParent?.isVisible || true}
      context={menuContext}
      isOpened={menuAddItemsModal}
      onChange={onCreateNewMenuItem}
      onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
    />
  );

  const isShowMobileEditMenu = editMenuItem && isMobileMenu && !isTransformMenuItem(editMenuItem);
  const editModal =
    editMenuItem &&
    (isShowMobileEditMenu ? (
      <AddOrEditMobileItem
        parentId={parentId}
        editItem={editMenuItem}
        menuItems={mobileMenuItems}
        isOpened={menuAddItemsModal}
        onEdit={onEditMobileMenuItem}
        onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
      />
    ) : (
      <AddOrEditItem
        context={menuContext}
        visibilityParent={currentParent?.isVisible || true}
        editItem={
          isTransformMenuItem(editMenuItem) ? getFlatDataStructure(editMenuItem) : undefined
        }
        isOpened={menuAddItemsModal}
        onChange={onEditMenuItemHandler}
        onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
      />
    ));

  return (
    <Page>
      <PageHeader
        title={`${currentTypeMenu ? menuName[currentTypeMenu] : 'Меню'}`}
        subTitle={`CODE: ${menuCode}`}
        backLink='/menu'
        breadcrumbs={breadcrumbs}
        rightButton={
          canUpdate && (
            <Button loading={isUpdating} onClick={onSaveHandler}>
              Сохранить
            </Button>
          )
        }
      />
      <PageBody>
        <Box>
          <ScrollArea.Autosize>
            {isMobileMenu ? (
              <div style={{ paddingBottom: '32px', height: '93vh', overflow: 'hidden' }}>
                <MobileMenuTabSite items={mobileMenuItems} />
              </div>
            ) : (
              <Tabs
                color='teal'
                defaultValue='first'
                keepMounted={false}
                style={{ height: '100%', position: 'relative' }}
              >
                <Tabs.List style={{ borderBottom: 'none' }}>
                  <Tabs.Tab
                    value='first'
                    color='primary'
                    onClick={() => onChangeTab(MenuContextEnum.CORP)}
                  >
                    Корпоративный сайт
                  </Tabs.Tab>
                  <Tabs.Tab
                    value='second'
                    color='primary'
                    onClick={() => onChangeTab(MenuContextEnum.USER)}
                  >
                    Пользовательский сайт
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value='first' pt='xs' style={{ height: '90vh', overflow: 'hidden' }}>
                  <MenuTabSite items={menuItems} />
                </Tabs.Panel>

                <Tabs.Panel
                  value='second'
                  pt='xs'
                  style={{ padding: '24px 0 34px 0', height: '90vh', overflow: 'hidden' }}
                >
                  <MenuTabSite items={menuItems} />
                </Tabs.Panel>
              </Tabs>
            )}
          </ScrollArea.Autosize>
          <LoadingOverlay visible={isFetching} style={{ position: 'absolute', top: '50%' }} />
        </Box>
      </PageBody>

      {addModal}

      {editModal}

      {deleteMenuItemId && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.TITLE}
          text={deleteItemText}
          confirmHandler={onDeleteHandler}
          opened={!!deleteMenuItemId}
          onClose={() => dispatch(setDeleteMenuItemId(null))}
        />
      )}
      {state === 'blocked' && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.OUT_TITLE}
          text={EAddNewItemConfirmationTexts.NOT_SAVE_MENU_SITE}
          confirmHandler={onConfirmLeave}
          opened={state === 'blocked'}
          onClose={onCancel}
        />
      )}
    </Page>
  );
};

import { FC } from 'react';
import { Box, Checkbox, Select, TextInput } from '@mantine/core';
import { GradientSelectItem } from '@/ui/containers/BannerCreationContainer/components/GradientSelectItem';
import { colorsOptions } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { useForm, yupResolver } from '@mantine/form';
import { MobileMenuParentItem } from '@prosv/core/types';
import { SubmitButton } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/SubmitButton';
import {
  AddOrEditParentMobileItemFields,
  PARENT_MOBILE_MENU_ITEMS_FORM,
} from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.constants';
import { getFormInitialData } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.helpers';
import { AddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.types';

interface Props {
  editItem?: MobileMenuParentItem;
  onSubmit: (values: AddParentMobileItem, resetForm: () => void) => void;
}

export const ParentContent: FC<Props> = ({ editItem, onSubmit }) => {
  const formValidation = useForm({
    initialValues: getFormInitialData(editItem),
    validate: yupResolver(PARENT_MOBILE_MENU_ITEMS_FORM),
  });

  const handleSubmit = (values: AddParentMobileItem) => {
    if (formValidation.isValid()) {
      onSubmit(values, formValidation.reset);
    } else {
      formValidation.validate();
    }
  };

  return (
    <form onSubmit={formValidation.onSubmit(handleSubmit)}>
      <Box>
        <TextInput
          withAsterisk
          label='Название'
          placeholder='Введите название'
          {...formValidation.getInputProps(AddOrEditParentMobileItemFields.MENU_NAME)}
        />
      </Box>

      <Box>
        <Select
          withAsterisk
          label='Фон'
          clearable
          placeholder='Выберите цвет фона'
          renderOption={({ option }) => <GradientSelectItem color='blue' {...option} />}
          data={colorsOptions}
          maxDropdownHeight={400}
          {...formValidation.getInputProps(AddOrEditParentMobileItemFields.MENU_BACKGROUND)}
        />
      </Box>

      <Checkbox
        label='Не отображать для ЮЛ'
        checked={formValidation.values[AddOrEditParentMobileItemFields.IS_HIDE_FOR_ENTITIES]}
        {...formValidation.getInputProps(AddOrEditParentMobileItemFields.IS_HIDE_FOR_ENTITIES)}
      />

      <SubmitButton />
    </form>
  );
};

import { colorsOptions } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { AddOrEditParentMobileItemFields } from './ParentContent.constants';
import { AddOrEditParentMobileItem, AddParentMobileItem } from './ParentContent.types';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';

export const getFormInitialData = (defaultValues?: AddOrEditParentMobileItem) => {
  return {
    [AddOrEditParentMobileItemFields.MENU_NAME]: defaultValues?.name || '',
    [AddOrEditParentMobileItemFields.MENU_BACKGROUND]:
      defaultValues?.setting.background || colorsOptions[0].value,
    [AddOrEditParentMobileItemFields.IS_HIDE_FOR_ENTITIES]: !(defaultValues
      ? defaultValues.setting.isShowForLegalEntity
      : true),
  };
};

export const isAddParentMobileItem = (
  data: AddParentMobileItem | AddChildMobileItem,
): data is AddParentMobileItem => AddOrEditParentMobileItemFields.MENU_BACKGROUND in data;

import { FC, useCallback, useEffect, useState } from 'react';
import { MobileMenuParentItem } from '@prosv/core/types';
import { DroppableDropDown } from '@/ui/containers/MenuContainer/DroppableDropDown';
import { MenuTabSiteWrapper } from '@/ui/containers/MenuContainer/MenuTabSiteWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentMenuType } from '@/store/slices/menu/menuTypes';
import {
  DragItemTypes,
  limitNestingItems,
  limitNestingLevel,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { useDrop } from 'react-dnd';
import { setMobileMenuItemsAction } from '@/store/slices/menu/menuItems';
import {
  getIndexesPathForMobileMenuItem,
  getMobileMenuItemByIndexes,
} from '@/ui/containers/MenuContainer/MobileMenuTabSite/MobileMenuTabSite.helpers';

interface Props {
  items: MobileMenuParentItem[] | null;
}

export const MobileMenuTabSite: FC<Props> = ({ items }) => {
  const dispatch = useDispatch();
  const currentMenuType = useSelector(selectCurrentMenuType);
  const [menuItems, setMenuItems] = useState<MobileMenuParentItem[] | null>(items);

  const limitNesting = (currentMenuType && limitNestingLevel[currentMenuType]) || 1;
  const limitItems = (currentMenuType && limitNestingItems[currentMenuType]) || 4;

  const findMenuItem = useCallback(
    (id: string) => {
      if (!menuItems) return;
      const indexes = getIndexesPathForMobileMenuItem(menuItems, id);
      if (!indexes) return;
      return { indexes };
    },
    [menuItems],
  );

  const moveMenuItem = useCallback(
    (hoverId: string, insertIndexes: number[]) => {
      if (!menuItems) return;
      const deleteIndexes = getIndexesPathForMobileMenuItem(menuItems, hoverId);
      if (!deleteIndexes) return;

      // Перемещать элементы можно только в рамках одного массива
      const deleteParentIndexes = deleteIndexes.slice(0, -1);
      const insertParentIndexes = insertIndexes.slice(0, -1);
      if (insertParentIndexes.join() === deleteParentIndexes.join()) {
        const cloneMenuItems = structuredClone(menuItems);
        const parent = getMobileMenuItemByIndexes(cloneMenuItems, deleteParentIndexes);
        // Если нет родителя, значит перестановка осуществляется на верхнем уровне
        if (!parent) {
          const deletedItem = cloneMenuItems.splice(deleteIndexes[0], 1)[0];
          cloneMenuItems.splice(insertIndexes[0], 0, deletedItem);
          setMenuItems(cloneMenuItems);
        } else if (parent?.items) {
          const deleteIndex = deleteIndexes[deleteIndexes.length - 1];
          const insertIndex = insertIndexes[insertIndexes.length - 1];
          const deletedItem = parent.items.splice(deleteIndex, 1)[0];
          parent.items.splice(insertIndex, 0, deletedItem);
          setMenuItems(cloneMenuItems);
        }
      }
    },
    [findMenuItem, menuItems, setMenuItems],
  );

  const [, drop] = useDrop(() => ({ accept: DragItemTypes.MENU }));

  useEffect(() => {
    dispatch(setMobileMenuItemsAction(menuItems));
  }, [dispatch, menuItems]);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  return (
    <MenuTabSiteWrapper ref={drop} wrapperStyle={{ paddingTop: 0 }}>
      {menuItems &&
        menuItems
          .filter((item) => !item.parentId)
          .map((item) => {
            return (
              <DroppableDropDown
                item={item}
                items={menuItems}
                limitNestingLevel={limitNesting}
                nestedElementLimit={limitItems}
                id={item.id}
                key={item.id}
                moveMenuItem={moveMenuItem}
                findMenuItem={findMenuItem}
              />
            );
          })}
    </MenuTabSiteWrapper>
  );
};

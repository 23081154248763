import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';

export const getMenuItemLevels = (parent: MobileMenuParentItem | MobileMenuItem | null): number => {
  const minItemLevel = 1;
  const maxItemLevel = 3;

  if (!parent) return minItemLevel;
  const upLevel = parent.level + 1;

  return upLevel <= maxItemLevel ? upLevel : maxItemLevel;
};
export const getModalTitle = (level: number, isNew: boolean) =>
  isNew ? `Добавление пункта меню ${level}-ого уровня` : 'Редактирование пункта меню';

export const jobMap = {
  DD: {
    title: 'Дивизиональный директор',
    weight: 1,
  },
  ZDD: {
    title: 'Заместитель дивизионального директора',
    weight: 2,
  },
  RD: {
    title: 'Региональный директор',
    weight: 3,
  },
  RM: {
    title: 'Региональный менеджер',
    weight: 4,
  },
  RMP: {
    title: 'Региональный менеджер',
    weight: 5,
  },
  VM: {
    title: 'Ведущий менеджер',
    weight: 6,
  },
  SM: {
    title: 'Старший менеджер',
    weight: 7,
  },
  SMP: {
    title: 'Старший менеджер по продажам',
    weight: 8,
  },
  MP: {
    title: 'Менеджер по продажам',
    weight: 9,
  },
};

export const peopleList = [
  {
    name: 'Сергей Сабиров',
    email: 'SSabirov@prosv.ru',
    phone: [
      {
        value: '+7 (912) 664-95-55',
        display: '+7 (912) 664 95 55',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/bfa9/49f7/a7d3/237c552e-bfa9-49f7-a7d3-572eb3e35dac-resized.webp',
    regions: [
      'RU-SPE',
      'RU-ARK',
      'RU-KGD',
      'RU-MUR',
      'RU-PSK',
      'RU-VLG',
      'RU-LEN',
      'RU-NGR',
      'RU-KO',
      'RU-KR',
      'RU-NEN',
      'RU-SVE',
      'RU-CHE',
      'RU-KHM',
      'RU-TYU',
      'RU-KGN',
      'RU-YAN',
    ],
  },
  {
    name: 'Мария Иванова',
    email: 'MVIvanova@prosv.ru',
    phone: [
      {
        value: '+7 (921) 913-81-05',
        display: '+7 (921) 913 81 05',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/60ad/4688/a932/ec3622f2-60ad-4688-a932-65bd714e3c8d-resized.webp',
    regions: ['RU-SPE', 'RU-KGD', 'RU-PSK', 'RU-NEN'],
  },
  {
    name: 'Артем Бакланов',
    email: 'ABaklanov@prosv.ru',
    phone: [
      {
        value: '+7 (912) 236-93-59',
        display: '+7 (912) 236 93 59',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/3036/4dd4/9a07/deb532ce-3036-4dd4-9a07-39f155d748a7-resized.webp',
    regions: ['RU-SVE', 'RU-CHE', 'RU-KHM', 'RU-TYU', 'RU-KGN', 'RU-YAN'],
  },
  {
    name: 'Ирина Бабкина',
    email: 'IBabkina@prosv.ru',
    phone: [
      {
        value: '+7 (916) 073-59-51',
        display: '+7 (916) 073 59 51',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/6d75/4c71/a1cf/e9fe89e3-6d75-4c71-a1cf-4c2f8b6fefef-resized.webp',
    regions: ['RU-VOR', 'RU-LIP', 'RU-KRS', 'RU-RYA', 'RU-TUL', 'RU-YAR'],
  },
  {
    name: 'Марина Крутова',
    email: 'MKrutova@prosv.ru',
    phone: [
      {
        value: '+7 (916) 936-00-19',
        display: '+7 (916) 936 00 19',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/90aa/4868/bcee/eb9fd4bf-90aa-4868-bcee-35ec32744583-resized.webp',
    regions: ['RU-MOS', 'RU-MOSCOW'],
  },
  {
    name: 'Сергей Злобин',
    email: 'SZlobin@prosv.ru',
    phone: [
      {
        value: '+7 (908) 271-32-71',
        display: '+7 (908) 271 32 71',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/a48a/42fc/9a9a/88fe5ac8-a48a-42fc-9a9a-08fd0ceeb8c8-resized.webp',
    regions: [
      'RU-DNR',
      'RU-LNR',
      'RU-ZO',
      'RU-HO',
      'RU-CR',
      'RU-SV',
      'RU-VGG',
      'RU-ROS',
      'RU-KDA',
      'RU-AD',
      'RU-KL',
      'RU-AST',
      'RU-STA',
      'RU-CE',
      'RU-DA',
      'RU-KB',
      'RU-SE',
      'RU-IN',
      'RU-KC',
    ],
  },
  {
    name: 'Ирина Щеглова',
    email: 'IShcheglova@prosv.ru',
    phone: [
      {
        value: '+7 (978) 826-42-97',
        display: '+7 (978) 826 42 97',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/d2e5/4682/9d2b/216a7b64-d2e5-4682-9d2b-acf1373491dc-resized.webp',
    regions: ['RU-ZO', 'RU-HO', 'RU-CR', 'RU-SV'],
  },
  {
    name: 'Мурад Бутаев',
    email: 'MButaev@prosv.ru',
    phone: [
      {
        value: '+7 (988) 440-02-48',
        display: '+7 (988) 440 02 48',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/1e1f/48f4/b709/7d94bbb7-1e1f-48f4-b709-3c0199933023-resized.webp',
    regions: ['RU-CE', 'RU-DA', 'RU-KB', 'RU-SE', 'RU-IN', 'RU-KC'],
  },
  {
    name: 'Эльвира Радачинская',
    email: 'ERadachinskaya@prosv.ru',
    phone: [
      {
        value: '+7 (918) 270-08-47',
        display: '+7 (918) 270 08 47',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/903a/4d3c/9e95/9f16c5e2-903a-4d3c-9e95-f17e6f28d65d-resized.webp',
    regions: ['RU-KDA', 'RU-AD', 'RU-KL'],
  },
  {
    name: 'Анна Бросман',
    email: 'ABrosman@prosv.ru',
    phone: [
      {
        value: '+7 (915) 464-76-03',
        display: '+7 (915) 464 76 03',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/410c/4995/8ab4/a3f03be7-410c-4995-8ab4-7d5d4835399d-resized.webp',
    regions: [
      'RU-SAM',
      'RU-ORE',
      'RU-BA',
      'RU-TA',
      'RU-ME',
      'RU-CU',
      'RU-ULY',
      'RU-SAR',
      'RU-MO',
      'RU-NIZ',
      'RU-PNZ',
      'RU-PER',
      'RU-KIR',
      'RU-UD',
    ],
  },
  {
    name: 'Наталья Масалкина',
    email: 'NMasalkina@prosv.ru',
    phone: [
      {
        value: '+7 (985) 770-33-82',
        display: '+7 (985) 770 33 82',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/61f9/4baf/b6f5/93c9c51d-61f9-4baf-b6f5-692f65eb4d20-resized.webp',
    regions: ['RU-SAM', 'RU-ME', 'RU-ULY', 'RU-PER', 'RU-KIR', 'RU-UD'],
  },
  {
    name: 'Петр Логинов',
    email: 'PLoginov@prosv.ru',
    phone: [
      {
        value: '+7 (905) 010-00-20',
        display: '+7 (905) 010 00 20',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/a2c7/473f/8553/af07a5a5-a2c7-473f-8553-080b3913f88e-resized.webp',
    regions: ['RU-ORE', 'RU-CU', 'RU-SAR', 'RU-MO', 'RU-NIZ', 'RU-PNZ'],
  },
  {
    name: 'Мария Барсукова',
    email: 'MBarsukova@prosv.ru',
    phone: [
      {
        value: '+7 (966) 135-94-54',
        display: '+7 (966) 135 94 54',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/3649/481a/a90e/2c1ae0e6-3649-481a-a90e-d0da0c33a223-resized.webp',
    regions: [
      'RU-KYA',
      'RU-IRK',
      'RU-NVS',
      'RU-TOM',
      'RU-ALT',
      'RU-KEM',
      'RU-OMS',
      'RU-KK',
      'RU-TY',
      'RU-AL',
    ],
  },
  {
    name: 'Оксана Курбатова',
    email: 'OKurbatova@prosv.ru',
    phone: [
      {
        value: '+7 (914) 871-37-54',
        display: '+7 (914) 871 37 54',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/cd3d/4141/a6ca/f69fa7d4-cd3d-4141-a6ca-3a927ad7f312-resized.webp',
    regions: ['RU-IRK'],
  },
  {
    name: 'Наталья Насимова',
    email: 'NNasimova@prosv.ru',
    phone: [
      {
        value: '+7 (905) 959-02-94',
        display: '+7 (905) 959 02 94',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/a20c/4901/b5e6/1ae71ef9-a20c-4901-b5e6-2b17c463b7d6-resized.webp',
    regions: ['RU-NVS', 'RU-TOM', 'RU-KEM', 'RU-AL'],
  },
  {
    name: 'Александр Цаплин',
    email: 'ATsaplin@prosv.ru',
    phone: [
      {
        value: '+7 (902) 945-65-62',
        display: '+7 (902) 945 65 62',
      },
      {
        value: '+7 (985) 826-62-19',
        display: '+7 (985) 826 62 19',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/d6cb/4dbd/9c25/33af6f08-d6cb-4dbd-9c25-6601761ee8dc-resized.webp',
    regions: ['RU-KYA', 'RU-ALT', 'RU-KK', 'RU-TY'],
  },
  {
    name: 'Дмитрий Дементьев',
    email: 'DDementev@prosv.ru',
    phone: [
      {
        value: '+7 (914) 319-93-91',
        display: '+7 (914) 319 93 91',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/c69a/4890/b721/262e37a4-c69a-4890-b721-4e8a234deaeb-resized.webp',
    regions: [
      'RU-KHA',
      'RU-SA',
      'RU-BU',
      'RU-ZAB',
      'RU-AMU',
      'RU-KAM',
      'RU-YEV',
      'RU-MAG',
      'RU-CHU',
    ],
  },
  {
    name: 'Валентина Мосензова',
    email: 'VMosenzova@prosv.ru',
    phone: [
      {
        value: '+7 (914) 703-48-34',
        display: '+7 (914) 703 48 34',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/aa8e/4128/b078/041ea7a8-aa8e-4128-b078-516ae33b9379-resized.webp',
    regions: [
      'RU-PRI',
      'RU-KHA',
      'RU-SA',
      'RU-BU',
      'RU-ZAB',
      'RU-AMU',
      'RU-SAK',
      'RU-KAM',
      'RU-YEV',
      'RU-MAG',
      'RU-CHU',
    ],
  },
  {
    name: 'Людмила Шашнова',
    email: 'LShashnova@prosv.ru',
    phone: [
      {
        value: '+7 (999) 026-06-73',
        display: '+7 (999) 026 06 73',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/db6a/4f83/be43/5cbb5202-db6a-4f83-be43-c53496f568b3-resized.webp',
    regions: ['RU-ARK', 'RU-NGR', 'RU-KR', 'RU-KO'],
  },
  {
    name: 'Светлана Рафф',
    email: 'SRaff@prosv.ru',
    phone: [
      {
        value: '+7 (911) 753-12-71',
        display: '+7 (911) 753 12 71',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/e6d2/41b5/afd1/293410a9-e6d2-41b5-afd1-36e40a2f370d-resized.webp',
    regions: ['RU-SPE', 'RU-PSK'],
  },
  {
    name: 'Ирина Крашенинникова',
    email: 'IKrasheninnikova@prosv.ru',
    phone: [
      {
        value: '+7 (905) 809-92-44',
        display: '+7 (905) 809 92 44',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/e0aa/44ff/adf2/a3cdbc10-e0aa-44ff-adf2-3a36dd57c482-resized.webp',
    regions: ['RU-SVE'],
  },
  {
    name: 'Светлана Тетушкина',
    email: 'STetushkina@prosv.ru',
    phone: [
      {
        value: '+7 (982) 676-57-99',
        display: '+7 (982) 676 57 99',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/0055/44e3/a152/d7071661-0055-44e3-a152-5400b7598bc4-resized.webp',
    regions: ['RU-TYU', 'RU-KGN', 'RU-YAN'],
  },
  {
    name: 'Софья Щербинина',
    email: 'SShcherbinina@prosv.ru',
    phone: [
      {
        value: '+7 (912) 688-16-82',
        display: '+7 (912) 688 16 82',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/b164/4af7/beb7/64bf984a-b164-4af7-beb7-929528f5be9a-resized.webp',
    regions: ['RU-CHE', 'RU-KHM'],
  },
  {
    name: 'Ольга Хаустова',
    email: 'OKhaustova@prosv.ru',
    phone: [
      {
        value: '+7 (915) 464-86-75',
        display: '+7 (915) 464 86 75',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/2a2a/4051/9934/11890344-2a2a-4051-9934-f9ad29f29aa3-resized.webp',
    regions: ['RU-KRS'],
  },
  {
    name: 'Наталья Давыдова',
    email: 'NDavydova@prosv.ru',
    phone: [
      {
        value: '+7 (915) 464-88-16',
        display: '+7 (915) 464 88 16',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/a59d/4a8f/94a2/a82ec98c-a59d-4a8f-94a2-7a250fe10c28-resized.webp',
    regions: ['RU-MOS'],
  },
  {
    name: 'Елена Брусова',
    email: 'EBrusova@prosv.ru',
    phone: [
      {
        value: '+7 (906) 796-07-93',
        display: '+7 (906) 796 07 93',
      },
    ],
    job: jobMap.SM.title,
    weight: jobMap.SM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/f3fa/4172/a28b/e0f3c688-f3fa-4172-a28b-7003543dfa0e-resized.webp',
    regions: ['RU-MOSCOW'],
  },
  {
    name: 'Ольга Кочкина',
    email: 'OKochkina@prosv.ru',
    phone: [
      {
        value: '+7 (915) 464-87-11',
        display: '+7 (915) 464 87 11',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/52ae/4606/bec6/68542441-52ae-4606-bec6-83f6d63bf891-resized.webp',
    regions: ['RU-VLA', 'RU-IVA', 'RU-SMO', 'RU-KOS'],
  },
  {
    name: 'Глеб Ершов',
    email: 'GErshov@prosv.ru',
    phone: [
      {
        value: '+7 (985) 020-14-38',
        display: '+7 (985) 020 14 38',
      },
    ],
    job: jobMap.VM.title,
    weight: jobMap.VM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/b1d5/4c73/b840/9b81a5c3-b1d5-4c73-b840-a02b452658d3-resized.webp',
    regions: ['RU-MOS'],
  },
  {
    name: 'Татьяна Громова',
    email: 'TVGromova@prosv.ru',
    phone: [
      {
        value: '+7 (917) 513-64-31',
        display: '+7 (917) 513 64 31',
      },
    ],
    job: jobMap.SMP.title,
    weight: jobMap.SMP.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/7682/4756/8eca/2dcf5769-7682-4756-8eca-c6c893675e91-resized.webp',
    regions: ['RU-MOS'],
  },
  {
    name: 'Татьяна Никишова',
    email: 'TNikishova@prosv.ru',
    phone: [
      {
        value: '+7 (926) 973-75-15',
        display: '+7 (926) 973 75 15',
      },
    ],
    job: jobMap.SMP.title,
    weight: jobMap.SMP.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/acc2/4ebe/9400/fb2d2abf-acc2-4ebe-9400-90c8b13f70fd-resized.webp',
    regions: ['RU-MOS'],
  },
  {
    name: 'Олег Кирюхин',
    email: 'OKiryukhin@prosv.ru',
    phone: [
      {
        value: '+7 (918) 377-12-18',
        display: '+7 (918) 377 12 18',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/feda/4c48/b6ae/9575745a-feda-4c48-b6ae-0220ffe74cd7-resized.webp',
    regions: ['RU-KDA', 'RU-AD'],
  },
  {
    name: 'Татьяна Мостовая',
    email: 'TMostovaya@prosv.ru',
    phone: [
      {
        value: '+7 (905) 438-35-62',
        display: '+7 (905) 438 35 62',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/8df1/43ca/931a/ad4a1d51-8df1-43ca-931a-6595d3dba511-resized.webp',
    regions: ['RU-KDA'],
  },
  {
    name: 'Ирина Теленьга',
    email: 'ITelenga@prosv.ru',
    phone: [
      {
        value: '+7 (918) 140-75-73',
        display: '+7 (918) 140 75 73',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/1585/41ec/8876/e14680f3-1585-41ec-8876-d04408ed9ab3-resized.webp',
    regions: ['RU-KDA'],
  },
  {
    name: 'Анна Корецкая',
    email: 'AKoretskaya@prosv.ru',
    phone: [
      {
        value: '+7 (961) 679 51 88',
        display: '+7 (961) 679 51 88',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/2a5a/4712/bf02/ffbe1c3d-2a5a-4712-bf02-02ddc1c3d877-resized.webp',
    regions: ['RU-VGG', 'RU-AST', 'RU-STA'],
  },
  {
    name: 'Анастасия Наборщикова',
    email: 'ANaborshchikova@prosv.ru',
    phone: [
      {
        value: '+7 (916) 073-44-69',
        display: '+7 (916) 073 44 69',
      },
      {
        value: '+7 (902) 477-17-65',
        display: '+7 (902) 477 17 65',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/b66b/4bcc/b6d6/0efa0a7d-b66b-4bcc-b6d6-3888e1e9ec58-resized.webp',
    regions: ['RU-PER'],
  },
  {
    name: 'Елена Липунцова',
    email: 'ELipuntsova@prosv.ru',
    phone: [
      {
        value: '+7 (903) 395-24-41',
        display: '+7 (903) 395 24 41',
      },
      {
        value: '+7 (985) 770-62-60',
        display: '+7 (985) 770 62 60',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/c448/4c01/9562/06ff2580-c448-4c01-9562-90f570699768-resized.webp',
    regions: ['RU-ORE'],
  },
  {
    name: 'Татьяна Быстрова',
    email: 'TBystrova@prosv.ru',
    phone: [
      {
        value: '+7 (927) 183-75-99',
        display: '+7 (927) 183 75 99',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/0bf6/4197/815e/cbcba4bd-0bf6-4197-815e-269e48acca4a-resized.webp',
    regions: ['RU-MO', 'RU-ME'],
  },
  {
    name: 'Елена Шкаликова',
    email: 'EShkalikova@prosv.ru',
    phone: [
      {
        value: '+7 (985) 170-79-72',
        display: '+7 (985) 170 79 72',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/61f5/4930/b76d/8db2279a-61f5-4930-b76d-eb094378d64f-resized.webp',
    regions: ['RU-TA', 'RU-ULY'],
  },
  {
    name: 'Ирина Яготинцева',
    email: 'IYagotintseva@prosv.ru',
    phone: [
      {
        value: '+7 (985) 770-43-66',
        display: '+7 (985) 770 43 66',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/a10c/45e9/9ba7/c0d51db2-a10c-45e9-9ba7-a7708c2c56dd-resized.webp',
    regions: ['RU-SAR'],
  },
  {
    name: 'Светлана Нифонтова',
    email: 'SNifontova@prosv.ru',
    phone: [
      {
        value: '+7 (902) 578-08-07',
        display: '+7 (902) 578 08 07',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/feb4/40a8/8e24/f49fb0f4-feb4-40a8-8e24-74dcb9d57799-resized.webp',
    regions: ['RU-IRK'],
  },
  {
    name: 'Людмила Юровская',
    email: 'LYurovskaya@prosv.ru',
    phone: [
      {
        value: '+7 (914) 000-46-23',
        display: '+7 (914) 000 46 23',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/0c55/4231/b272/9d9aa920-0c55-4231-b272-bd76d5ba23dd-resized.webp',
    regions: ['RU-IRK'],
  },
  {
    name: 'Ольга Сысалетина',
    email: 'o.syssaletina@prosv.ru',
    phone: [
      {
        value: '+7 (952) 944-82-37',
        display: '+7 (952) 944 82 37',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/614c/43fc/a113/b76633de-614c-43fc-a113-230d740ffc1c-resized.webp',
    regions: ['RU-NVS', 'RU-TOM'],
  },
  {
    name: 'Ольга Сатырова',
    email: 'OSatyrova@prosv.ru',
    phone: [
      {
        value: '+7 (914) 271-49-62',
        display: '+7 (914) 271 49 62',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/42ff/4374/a029/9647aec6-42ff-4374-a029-7b8e8cb3a509-resized.webp',
    regions: ['RU-SA'],
  },
  {
    name: 'Анна Горева',
    email: 'AGoreva@prosv.ru',
    phone: [
      {
        value: '+7 (924) 183-96-48',
        display: '+7 (924) 183 96 48',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/2b06/405b/8b52/9d002916-2b06-405b-8b52-9b1daa436274-resized.webp',
    regions: ['RU-KHA', 'RU-KAM', 'RU-MAG'],
  },
  {
    name: 'Рыболовлева Алёна',
    email: 'ARybolovleva@prosv.ru',
    phone: [
      {
        value: '+7 (911) 200-02-19',
        display: '+7 (911) 200 02 19',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/f95e/41ed/9396/13ac2c3c-f95e-41ed-9396-e4da0adb8239-resized.webp',
    regions: ['RU-ARK', 'RU-MUR', 'RU-VLG', 'RU-LEN', 'RU-NGR', 'RU-KO', 'RU-KR'],
  },
  {
    name: 'Оксана Антонова',
    email: 'OAntonova@prosv.ru',
    phone: [
      {
        value: '+7 (915) 040-07-80',
        display: '+7 (915) 040 07 80',
      },
    ],
    job: jobMap.SM.title,
    weight: jobMap.SM.weight,
    avatar:
      'https://storage.yandexcloud.net/prod-file-public/16fb/4eb2/88e8/51d859d5-16fb-4eb2-88e8-d54566db06b2-resized.webp',
    regions: ['RU-MOS'],
  },
  {
    name: 'Злата Саликовская',
    email: 'ZSalikovskaya@prosv.ru',
    phone: [
      {
        value: '+8 (981) 151-25-48',
        display: '+8 (981) 151 25 48',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-KGD', 'RU-NEN'],
  },
  {
    name: 'Алина Ахмедьянова',
    email: 'AAkhmedyanova@prosv.ru',
    phone: [
      {
        value: '+8 (981) 200-79-71',
        display: '+8 (981) 200 79 71',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-MUR', 'RU-VLG', 'RU-LEN'],
  },
  {
    name: 'Мария Жучкова',
    email: 'MVZhuchkova@prosv.ru',
    phone: [
      {
        value: '+7 (965) 408-68-24',
        display: '+7 (965) 408 68 24',
      },
    ],
    job: jobMap.DD.title,
    weight: jobMap.DD.weight,
    avatar: '',
    regions: [
      'RU-TVE',
      'RU-BEL',
      'RU-BRY',
      'RU-KLU',
      'RU-TAM',
      'RU-ORL',
      'RU-VOR',
      'RU-LIP',
      'RU-KRS',
      'RU-RYA',
      'RU-TUL',
      'RU-YAR',
      'RU-VLA',
      'RU-IVA',
      'RU-SMO',
      'RU-KOS',
    ],
  },
  {
    name: 'Елена Каганова',
    email: 'EKaganova@prosv.ru',
    phone: [
      {
        value: '+7 (985) 171-06-48',
        display: '+7 (985) 171 06 48',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-BEL', 'RU-TVE', 'RU-BRY', 'RU-KLU', 'RU-TAM', 'RU-ORL'],
  },
  {
    name: 'Ольга Туревич',
    email: 'OTurevich@prosv.ru',
    phone: [
      {
        value: '+7 (985) 020-14-39',
        display: '+7 (985) 020 14 39',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-MOSCOW'],
  },
  {
    name: 'Лариса Кузнецова',
    email: 'LVKuznetsova@prosv.ru',
    phone: [
      {
        value: '+8 (926) 371-35-66',
        display: '+8 (926) 371 35 66',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-MOSCOW'],
  },
  {
    name: 'Нана Кислякова',
    email: 'NKislyakova@prosv.ru',
    phone: [
      {
        value: '+7 (949) 420-99-58',
        display: '+7 (949) 420 99 58',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-DNR', 'RU-LNR'],
  },
  {
    name: 'Елена Болотова',
    email: 'EBolotova@prosv.ru',
    phone: [
      {
        value: '+7 (961) 679-51-01',
        display: '+7 (961) 679 51 01',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-VGG', 'RU-AST', 'RU-STA'],
  },
  {
    name: 'Эльжбета Кикичева',
    email: 'EKikicheva@prosv.ru',
    phone: [
      {
        value: '+7 (989) 636-43-31',
        display: '+7 (989) 636 43 31',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-ROS'],
  },
  {
    name: 'Алексей Ткаченко',
    email: 'ATkachenko@prosv.ru',
    phone: [
      {
        value: '+7 (909) 427-43-06',
        display: '+7 (909) 427 43 06',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-ROS'],
  },
  {
    name: 'Диана Айгильдина',
    email: 'DAygildina@prosv.ru',
    phone: [
      {
        value: '+7 (963) 895-97-27',
        display: '+7 (963) 895 97 27',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-BA'],
  },
  {
    name: 'Анна Телешева',
    email: 'ATelesheva@prosv.ru',
    phone: [
      {
        value: '+7 (930) 807-00-49',
        display: '+7 (930) 807 00 49',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-NIZ'],
  },
  {
    name: 'Оксана Волкова',
    email: 'OAVolkova@prosv.ru',
    phone: [
      {
        value: '+8 (905) 013-92-22',
        display: '+8 (905) 013 92 22',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-NIZ'],
  },
  {
    name: 'Никита Столов',
    email: 'NStolov@prosv.ru',
    phone: [
      {
        value: '+8 (909) 062-40-33',
        display: '+8 (909) 062 40 33',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-UD'],
  },
  {
    name: 'Оксана Сукова',
    email: 'OSukova@prosv.ru',
    phone: [
      {
        value: '+8 (908) 440-73-22',
        display: '+8 (908) 440 73 22',
      },
    ],
    job: jobMap.RD.title,
    weight: jobMap.RD.weight,
    avatar: '',
    regions: ['RU-PRI', 'RU-SAK'],
  },
  {
    name: 'Ольга Жамсуева',
    email: 'OZhamsueva@prosv.ru',
    phone: [
      {
        value: '+8 (983) 438-93-89',
        display: '+8 (983) 438 93 89',
      },
    ],
    job: jobMap.RM.title,
    weight: jobMap.RM.weight,
    avatar: '',
    regions: ['RU-BU', 'RU-ZAB'],
  },
];

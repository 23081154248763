import { useCallback } from 'react';

import { buildDataForUpdate } from '@/utils/getTransformMenuItems';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import {
  MenuContextEnum,
  MenuTypeEnum,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import {
  TEditMenuItem,
  TSettingsData,
  TTransformMenuItem,
} from '@/ui/containers/MenuContainer/MenuContainer.types';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

import {
  fetchUpdateMenuAction,
  fetchUpdateMobileMenuAction,
  setDeleteMenuItem,
  setDeleteMenuItemId,
  setEditMenuItem,
  setMenuItem,
  setMobileMenuItem,
  setParentId,
  setUpdatedMenuItem,
  setUpdatedMobileMenuItem,
} from '@/store/slices/menu/menuItems';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { AddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.types';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';

interface UseMenuHandlersParams {
  dispatch: ReturnType<typeof useAppDispatch>;
  menuItems: TTransformMenuItem[] | null;
  mobileMenuItems: MobileMenuParentItem[] | null;
  currentTypeMenu: string | null;
  menuContext: MenuContextEnum;
  anotherContextItems: TSettingsData[] | null;
  deleteMenuItemId: string | null;
}

export const useMenuHandlers = ({
  dispatch,
  menuItems,
  mobileMenuItems,
  currentTypeMenu,
  menuContext,
  anotherContextItems,
  deleteMenuItemId,
}: UseMenuHandlersParams) => {
  const onCreateNewMenuItem = useCallback(
    (values: Omit<TAddOrEditItem, 'id'>, id: string | null) => {
      dispatch(setMenuItem({ values, parentId: id }));
      dispatch(setParentId(null));
    },
    [dispatch],
  );

  const onCreateNewMobileMenuItem = useCallback(
    (values: AddParentMobileItem | AddChildMobileItem, parentId: string | null) => {
      dispatch(setMobileMenuItem({ values, parentId }));
      dispatch(setParentId(null));
    },
    [dispatch],
  );

  const onEditMobileMenuItem = useCallback(
    (
      values: AddParentMobileItem | AddChildMobileItem,
      editedItem: MobileMenuParentItem | MobileMenuItem,
    ) => {
      dispatch(setUpdatedMobileMenuItem({ values, editedItem }));
      dispatch(setEditMenuItem({ item: null, parentId: null }));
    },
    [dispatch],
  );

  const onEditMenuItemHandler = useCallback(
    (values: TEditMenuItem | Omit<TAddOrEditItem, 'id'>) => {
      dispatch(setUpdatedMenuItem({ updatedMenu: values, menuItems }));
      dispatch(setEditMenuItem({ item: null, parentId: null }));
    },
    [dispatch, menuItems],
  );

  const onDeleteHandler = useCallback(() => {
    if (deleteMenuItemId) {
      dispatch(setDeleteMenuItem(deleteMenuItemId));
      dispatch(setDeleteMenuItemId(null));
    }
  }, [dispatch, deleteMenuItemId]);

  const onSaveHandler = useCallback(() => {
    if (currentTypeMenu) {
      if (MenuTypeEnum.MOBILE_MENU === currentTypeMenu) {
        if (mobileMenuItems) {
          dispatch(fetchUpdateMobileMenuAction(mobileMenuItems));
        }
      } else {
        if (menuItems) {
          dispatch(
            fetchUpdateMenuAction({
              data: buildDataForUpdate(menuItems, menuContext, anotherContextItems),
              menuType: currentTypeMenu,
            }),
          );
        }
      }
    }
  }, [dispatch, menuItems, mobileMenuItems, currentTypeMenu, menuContext, anotherContextItems]);

  return {
    onCreateNewMenuItem,
    onCreateNewMobileMenuItem,
    onEditMenuItemHandler,
    onEditMobileMenuItem,
    onDeleteHandler,
    onSaveHandler,
  };
};

import React, { FC, useRef } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import { getFinalImageLink, getFormInitialData } from './ChildContent.helpers';
import { MobileMenuItem } from '@prosv/core/types';
import { SubmitButton } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/SubmitButton';
import { Box, Checkbox, Group, Input, Radio, TextInput } from '@mantine/core';
import styles from './ChildContent.module.scss';
import { MenuItemTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { FileLoader } from '@/ui/components/FileLoader';
import { v4 as uuid_v4 } from 'uuid';
import {
  AddOrEditChildMobileItemFields,
  CHILD_MOBILE_MENU_ITEMS_FORM,
} from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.constants';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';

interface Props {
  editItem?: MobileMenuItem;
  level: number;
  onSubmit: (values: AddChildMobileItem, resetForm: () => void) => void;
}

export const ChildContent: FC<Props> = ({ editItem, onSubmit, level }) => {
  const sessionID = useRef(uuid_v4());
  const withIcon = level === 2;
  const formValidation = useForm({
    initialValues: getFormInitialData(withIcon, editItem),
    validate: yupResolver(CHILD_MOBILE_MENU_ITEMS_FORM),
  });

  const handleSubmit = async (values: AddChildMobileItem) => {
    if (formValidation.isValid()) {
      const finalIconLink = await getFinalImageLink(
        values[AddOrEditChildMobileItemFields.ICON],
        sessionID.current,
      );
      onSubmit(
        {
          ...values,
          [AddOrEditChildMobileItemFields.ICON]: finalIconLink,
        },
        formValidation.reset,
      );
    } else {
      formValidation.validate();
    }
  };

  const imgData = formValidation.getInputProps(AddOrEditChildMobileItemFields.ICON).value;

  return (
    <form onSubmit={formValidation.onSubmit(handleSubmit)}>
      <Box>
        <TextInput
          withAsterisk
          label='Название'
          placeholder='Введите название'
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.MENU_NAME)}
        />
      </Box>
      <Radio.Group
        className={styles.typeRadioGroup}
        value={formValidation.values[AddOrEditChildMobileItemFields.ITEM_TYPE]}
        label='Тип пункта'
        {...formValidation.getInputProps(AddOrEditChildMobileItemFields.ITEM_TYPE)}
      >
        <Group>
          <Radio value={MenuItemTypeEnum.INTERNAL} label='Страница сайта' />
          <Radio value={MenuItemTypeEnum.EXTERNAL} label='Внешняя ссылка' />
        </Group>
      </Radio.Group>
      {formValidation.values[AddOrEditChildMobileItemFields.ITEM_TYPE] ===
      MenuItemTypeEnum.INTERNAL ? (
        <TextInput
          withAsterisk
          label='Url'
          placeholder='Введите ссылку'
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.URL)}
        />
      ) : (
        <TextInput
          withAsterisk
          label='Url'
          placeholder='Введите внешнюю ссылку'
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.URL)}
        />
      )}
      <Box className={styles.visibilityBox}>
        <Checkbox
          label='Добавить атрибут nofollow'
          checked={formValidation.values[AddOrEditChildMobileItemFields.IS_NO_FOLLOW]}
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.IS_NO_FOLLOW)}
        />
        <Checkbox
          label='Не отображать для ЮЛ'
          checked={formValidation.values[AddOrEditChildMobileItemFields.IS_HIDE_FOR_ENTITIES]}
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.IS_HIDE_FOR_ENTITIES)}
        />
      </Box>
      {withIcon && (
        <Input.Wrapper
          label='Загрузите файл'
          {...formValidation.getInputProps(AddOrEditChildMobileItemFields.ICON)}
        >
          <FileLoader
            savedFormFiles={imgData ? [imgData] : undefined}
            onRemove={() => (formValidation.values[AddOrEditChildMobileItemFields.ICON] = null)}
            onChange={(params) => {
              formValidation.setFieldValue(AddOrEditChildMobileItemFields.ICON, {
                name: params.name,
                id: params.id,
                preview: params.path,
              });
            }}
            error={formValidation.getInputProps(AddOrEditChildMobileItemFields.ICON).error}
            sessionID={sessionID.current}
            placeholder='Перетащите или добавьте файл'
          />
        </Input.Wrapper>
      )}
      <SubmitButton />
    </form>
  );
};

import { TPutMenuItems } from '@/types';

import { isEmpty } from 'lodash';

import {
  MenuContextEnum,
  MenuOppositeContext,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import {
  IMenusById,
  IUpdatedMenu,
  TAcc,
  TEditMenuItem,
  TMenuData,
  TMenuDropdownItem,
  TMenuItemInnerSettings,
  TMenuSettings,
  TSettingsData,
  TTransformMenuItem,
} from '@/ui/containers/MenuContainer/MenuContainer.types';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { isMobileMenuItemLevels } from '@/store/slices/menu/menu.helpers';
import { AddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.types';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';
import { isMobileMenuParentItem } from '@prosv/core/types/bff/menu/helpers';
import { isAddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.helpers';
import { AddOrEditParentMobileItemFields } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.constants';
import { AddOrEditChildMobileItemFields } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.constants';

const convertArraySettingToObject = (array: TMenuItemInnerSettings[]): TMenuSettings => {
  return array.reduce((obj: TMenuSettings, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};

export const transformMenuItems = (data: TMenuData, context: MenuContextEnum) => {
  const itemsContext = data.settings?.filter((settingItem) => settingItem.context === context)[0];
  if (!itemsContext) {
    return [];
  }
  const settings = convertArraySettingToObject(itemsContext.items);

  return data.items
    .map((item) => ({
      ...item,
      ...settings[item.id],
    }))
    .sort((a, b) => a.sortWeight - b.sortWeight);
};

export const getFlatDataStructure = (item: TTransformMenuItem) => {
  const { id, parentId, name, isVisible, sortWeight, setting } = item;
  return { id, parentId, name, isVisible, sortWeight, ...setting };
};

export const buildDataForUpdateItem = (data: TEditMenuItem) => {
  const {
    isNofollow,
    parentId,
    name,
    isVisible,
    url,
    isBold,
    itemType,
    isBurgerBottom,
    sortWeight,
    id,
  } = data;
  return {
    parentId,
    name,
    isVisible,
    sortWeight,
    id,
    setting: {
      isBold,
      isBurgerBottom,
      isNofollow,
      itemType,
      url,
    },
  };
};

export const buildDataForUpdateMobileItem = (
  editedItem: MobileMenuParentItem | MobileMenuItem,
  values: AddParentMobileItem | AddChildMobileItem,
): MobileMenuParentItem | MobileMenuItem => {
  if (isMobileMenuParentItem(editedItem) && isAddParentMobileItem(values)) {
    return {
      id: editedItem.id,
      name: values[AddOrEditParentMobileItemFields.MENU_NAME],
      level: editedItem.level,
      parentId: editedItem.parentId,
      setting: {
        background: values[AddOrEditParentMobileItemFields.MENU_BACKGROUND],
        isShowForLegalEntity: !values[AddOrEditParentMobileItemFields.IS_HIDE_FOR_ENTITIES],
        sortWeight: editedItem.setting.sortWeight,
      },
      items: editedItem.items,
    };
  } else if (!isMobileMenuParentItem(editedItem) && !isAddParentMobileItem(values)) {
    return {
      id: editedItem.id,
      parentId: editedItem.parentId,
      level: editedItem.level,
      name: values[AddOrEditParentMobileItemFields.MENU_NAME],
      setting: {
        url: values[AddOrEditChildMobileItemFields.URL],
        itemType: values[AddOrEditChildMobileItemFields.ITEM_TYPE],
        isNofollow: values[AddOrEditChildMobileItemFields.IS_NO_FOLLOW],
        icon: values[AddOrEditChildMobileItemFields.ICON]?.preview,
        isShowForLegalEntity: !values[AddOrEditChildMobileItemFields.IS_HIDE_FOR_ENTITIES],
        sortWeight: editedItem.setting.sortWeight,
      },
      items: editedItem.items,
    };
  }
  return editedItem;
};

export const buildDataForNewItem = (
  data: TEditMenuItem,
  sortWeight: number,
  parentId: string,
  id: string,
) => {
  const { isNofollow, name, isVisible, url, isBold, itemType, isBurgerBottom } = data;
  return {
    name,
    isVisible,
    sortWeight,
    parentId,
    id,
    setting: {
      isBold,
      isBurgerBottom,
      isNofollow,
      itemType,
      url,
    },
  };
};

export const buildDataForNewMobileParentItem = (
  menuItems: MobileMenuParentItem[],
  { name, background, hideForEntities }: AddParentMobileItem,
  id: string,
): MobileMenuParentItem => {
  const lastItem = menuItems[menuItems.length - 1];
  return {
    id,
    name: name,
    level: 1,
    parentId: null,
    setting: {
      background: background,
      isShowForLegalEntity: !hideForEntities,
      sortWeight: (lastItem?.setting.sortWeight || 0) + 1,
    },
    items: null,
  };
};

export const buildDataForNewMobileItem = (
  { name, itemType, url, isNofollow, hideForEntities, icon }: AddChildMobileItem,
  id: string,
  parent: MobileMenuParentItem | MobileMenuItem,
): MobileMenuItem => {
  const level = parent.level + 1;
  const sortWeight = parent.items?.length
    ? parent.items[parent.items.length - 1].setting.sortWeight + 1
    : 1;

  return {
    id,
    parentId: parent.id,
    name,
    level: isMobileMenuItemLevels(level) ? level : 3,
    setting: {
      url,
      itemType,
      isNofollow,
      icon: icon?.preview || undefined,
      isShowForLegalEntity: !hideForEntities,
      sortWeight,
    },
    items: null,
  };
};

export const buildDataForUpdate = (
  menu: TTransformMenuItem[],
  context: MenuContextEnum,
  anotherContextItems: TSettingsData[] | null,
): TPutMenuItems => {
  const initialValue: TAcc = {
    items: [],
    settings: [],
  };

  const builtData = menu.reduce((acc: TAcc, item) => {
    const { name, parentId, id, setting, sortWeight, isVisible } = item;

    if (isEmpty(acc)) {
      return (acc = {
        items: [{ name, parentId, id, setting }],
        settings: [{ id, sortWeight, isVisible }],
      });
    }

    return (acc = {
      items: [...acc?.items, { name, parentId, id, setting }],
      settings: [...acc?.settings, { id, sortWeight, isVisible }],
    });
  }, initialValue);

  let anotherContexts;

  const ids = builtData.items.map((item: TMenuDropdownItem) => item.id);

  if (!anotherContextItems || isEmpty(anotherContextItems)) {
    anotherContexts = [
      { context: MenuOppositeContext[context] as MenuContextEnum, items: builtData.settings },
    ];
  } else {
    anotherContexts = anotherContextItems.map((setting) => ({
      ...setting,
      items: setting.items.filter((item) => ids.includes(item.id)),
    }));
  }

  return {
    items: builtData.items,
    settings: [
      {
        context,
        items: builtData.settings,
      },
      ...anotherContexts,
    ],
  };
};

export const updateChildrenMenu = (
  menus: TTransformMenuItem[],
  res: TTransformMenuItem[],
  itemsByParentId: IMenusById,
  updatedMenu: IUpdatedMenu,
) => {
  menus.forEach((item) => {
    if (item.parentId === updatedMenu.id) {
      res.push({
        ...item,
        isVisible: updatedMenu.isVisible,
        setting: {
          ...item.setting,
          isBurgerBottom: updatedMenu.isBurgerBottom,
        },
      });
      const children = itemsByParentId[item.id];
      if (children) {
        children.forEach((childItem) => {
          res.push({
            ...childItem,
            isVisible: updatedMenu.isVisible,
            setting: {
              ...childItem.setting,
              isBurgerBottom: updatedMenu.isBurgerBottom,
            },
          });
        });
      }
    }
  });
  return res;
};

export const getMenusByParentId = (arr: TTransformMenuItem[]): IMenusById => {
  return arr.reduce((result: IMenusById, el) => {
    const parentId = el.parentId;
    if (parentId) {
      if (parentId in result) {
        result[parentId].push(el);
      } else {
        result[parentId] = [el];
      }
    }
    return result;
  }, {});
};

import { FC } from 'react';
import { Box, Button, Paper } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import { DictionaryItemModal } from './DictionaryItemModal';
import { useDictionaryItemsColumns } from './DictionaryItems.columns';
import { useDictionaryItems } from './DictionaryItems.hooks';
import { useDictionaryItemsState } from './DictionaryItems.state';
import { DictionaryItemsFilter } from './DictionaryItemsFilter';

import styles from './DictionaryItems.module.scss';

export const DictionaryItems: FC = () => {
  const canCreate = usePermission('DICTIONARY_CREATE');
  const { state, controls } = useDictionaryItemsState();
  const { isLoading, breadcrumbs, dictionary, data } = useDictionaryItems(state);
  const { columns } = useDictionaryItemsColumns({ dictionary, state, controls });

  return (
    <Box className={styles.root}>
      <PageHeader
        title={dictionary?.name || ''}
        subTitle={dictionary?.code || ''}
        backLink='/dictionary'
        breadcrumbs={breadcrumbs}
        rightButton={
          canCreate ? (
            <>
              <Button onClick={() => controls.setModal(true)} style={{ width: '200px' }}>
                Создать
              </Button>
            </>
          ) : null
        }
      />

      <Paper radius={8} p={24} pb={12} shadow='xs' className={styles.paper}>
        <DictionaryItemsFilter
          value={state.filter}
          changeFilter={controls.changeFilter}
          reset={controls.reset}
          isClear={state.isClear}
        />

        <CustomTable
          status={isLoading ? 'loading' : 'loaded'}
          columns={columns}
          total={data?.total}
          data={data?.items}
          limit={state.pagination.limit}
          currentPage={state.page}
          onChangePagination={controls.changePage}
          onChangeLimit={controls.changeLimit}
          noData='Словарь пуст.'
        />
      </Paper>

      {dictionary && (
        <DictionaryItemModal
          dictionary={dictionary}
          opened={state.isOpen}
          item={state.item}
          onClose={() => controls.setModal(false)}
        />
      )}
    </Box>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getRequestFilter } from './DictionaryItems.helpers';
import { DictionaryItemsState } from './DictionaryItems.types';

import { useDictionary, useDictionaryItemsExtended } from '@/store/slices/dictionary';

export const useDictionaryItems = <Dict extends string = string>({
  filter: filterValue,
  sort,
  pagination,
}: DictionaryItemsState<Dict>) => {
  const code = (useParams().code ?? '') as Dict;

  const { data: dictionary, isLoading: isLoadingDictionary } = useDictionary(code);
  const filter = useMemo(
    () => getRequestFilter<Dict>(filterValue, dictionary?.additionalFields),
    [dictionary?.additionalFields, filterValue],
  );

  const {
    data,
    isLoading: isLoadingItems,
    isFetching,
  } = useDictionaryItemsExtended<Dict>({
    code,
    filter,
    sort,
    pagination,
  });

  const isLoading = isLoadingDictionary || isLoadingItems || isFetching;

  const breadcrumbs = useMemo(
    () => [
      { name: 'Справочники', url: '/dictionary' },
      { name: dictionary?.name || '', url: `/dictionary/${code}` },
    ],
    [dictionary, code],
  );

  return { isLoading, breadcrumbs, dictionary, data };
};

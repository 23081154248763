import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { MobileMenuItemLevels } from '@prosv/core/types/bff/menu/menu';
import { AddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.types';
import { AddOrEditParentMobileItemFields } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.constants';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';

export const recursiveSplice = <Item extends MobileMenuParentItem | MobileMenuItem>(
  data: Item[],
  id: string,
) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.id === id) {
      data.splice(i, 1);
      return data; // Успешно удалили элемент
    }

    // Если есть вложенные элементы, ищем в них
    if (item.items && recursiveSplice(item.items, id)) {
      return data; // Успешно удалили элемент на вложенном уровне
    }
  }

  return false; // Элемент с данным id не найден
};

export const addContextToMobileMenu = <Item extends MobileMenuParentItem | MobileMenuItem>(
  menu: Item[],
): Item[] =>
  menu.map((el) => ({
    ...el,
    items: el.items ? addContextToMobileMenu(el.items) : null,
    setting: {
      ...el.setting,
      context: ['user', 'corp'],
    },
  }));

export const isAddParentMobileItem = (
  values: AddParentMobileItem | AddChildMobileItem,
): values is AddParentMobileItem => AddOrEditParentMobileItemFields.MENU_BACKGROUND in values;

export const isMobileMenuItemLevels = (
  number: number,
): number is Exclude<MobileMenuItemLevels, 1> => number >= 2 && number <= 3;

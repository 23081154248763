import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Collapse } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import {
  DEFAULT_LIMIT_ITEMS,
  DragItemTypes,
  NESTED_DEFAULT_LIMIT,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';

import { DropdownButtonItems } from './DropdownItemButtons';

import styles from './DroppableDropDown.module.scss';
import { useDropItemActions } from '@/ui/containers/MenuContainer/DroppableDropDown/DroppableDropDown.hooks';
import {
  checkIsCollapsable,
  getChildItems,
} from '@/ui/containers/MenuContainer/DroppableDropDown/DroppableDropDown.helpers';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { isTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.helpers';

type MenuItem = TTransformMenuItem | MobileMenuParentItem | MobileMenuItem;

interface IDroppableDropDown<Item extends MenuItem> {
  item: Item;
  items: Item[];
  id: string;
  nestingLevel?: number;
  limitNestingLevel?: number;
  nestedElementLimit?: number;
  moveMenuItem: (id: string, hoverIndexes: number[]) => void;
  findMenuItem: (id: string) => { indexes: number[] } | undefined;
}

export const DroppableDropDown = <Item extends MenuItem>({
  item,
  id,
  items,
  moveMenuItem,
  findMenuItem,
  nestingLevel = 0,
  limitNestingLevel = NESTED_DEFAULT_LIMIT,
  nestedElementLimit = DEFAULT_LIMIT_ITEMS,
}: IDroppableDropDown<Item>) => {
  const canUpdate = usePermission('MENU_UPDATE');

  const haveUrl = 'url' in item.setting && !!item.setting.url;
  const isVisible = !isTransformMenuItem(item) || item.isVisible;
  const itemActions = useDropItemActions(item);
  const isCollapse = checkIsCollapsable(items, item);
  const childElements = getChildItems(items, item);

  const [opened, setOpened] = useState<boolean>(false);

  const originalIndexes = findMenuItem(id)?.indexes;

  const [, drag] = useDrag(
    () => ({
      type: DragItemTypes.MENU,
      item: { id, originalIndexes },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (dragItem, monitor) => {
        const { id: droppedId, originalIndexes: indexes } = dragItem;
        const didDrop = monitor.didDrop();
        // возвращает на место, если дроп был незавершен
        if (!didDrop) {
          moveMenuItem(droppedId, indexes || [0]);
        }
      },
    }),
    [id, originalIndexes, moveMenuItem],
  );

  const [, drop] = useDrop(
    () => ({
      accept: DragItemTypes.MENU,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const hoverIndexes = findMenuItem(id)?.indexes;
          moveMenuItem(draggedId, hoverIndexes || [0]);
        }
      },
    }),
    [findMenuItem, moveMenuItem],
  );

  const isDisableAddBtn =
    nestingLevel >= limitNestingLevel || childElements.length >= nestedElementLimit;

  const handleOpen = () => {
    setOpened((prev) => !prev);
  };

  const onDragHandler = (node: HTMLDivElement) => {
    drag(drop(node));
  };

  return (
    <>
      <Box
        className={cn(styles.root, !isVisible && styles.unvisible, 'can')}
        onClick={handleOpen}
        ref={onDragHandler}
      >
        {isCollapse && (
          <Box
            style={{
              marginRight: 12,
              transform: !opened ? 'rotate(180deg)' : '',
            }}
            className='can'
          >
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='can'
            >
              <path
                className='can'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.7489 7.17754C11.4142 7.51227 10.8715 7.51227 10.5368 7.17754L6 2.64077L1.46323 7.17754C1.1285 7.51227 0.585787 7.51227 0.251052 7.17754C-0.083684 6.8428 -0.083684 6.30009 0.251052 5.96536L5.39391 0.822501C5.72864 0.487766 6.27136 0.487766 6.60609 0.822501L11.7489 5.96535C12.0837 6.30009 12.0837 6.8428 11.7489 7.17754Z'
                fill='#121212'
              />
            </svg>
          </Box>
        )}

        <span className='can'>
          <b className='can'>{item?.name} &nbsp;</b>
        </span>
        {canUpdate && (
          <DropdownButtonItems
            actions={itemActions}
            disableCopyAction={!haveUrl}
            disableAddAction={isDisableAddBtn}
          />
        )}
      </Box>
      <Box
        style={{
          marginLeft: 40,
        }}
      >
        <Collapse in={opened} transitionDuration={100} transitionTimingFunction='linear'>
          {!!items.length &&
            childElements.map((itemInner) => (
              <DroppableDropDown
                items={items}
                item={itemInner}
                nestingLevel={nestingLevel + 1}
                limitNestingLevel={limitNestingLevel}
                nestedElementLimit={nestedElementLimit}
                id={itemInner.id}
                key={itemInner.id}
                moveMenuItem={moveMenuItem}
                findMenuItem={findMenuItem}
              />
            ))}
        </Collapse>
      </Box>
    </>
  );
};

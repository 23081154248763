import * as Yup from 'yup';

import { STRING_REGEXP, VALIDATION_LINK } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import { MenuItemTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { EMenuContainerAddNewItemConstants } from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem.constants';

export const VALIDATION_STRING = Yup.string()
  .matches(STRING_REGEXP, validationTexts.STRING)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

export const NEW_MENU_ITEM_FORM = Yup.object().shape({
  [EMenuContainerAddNewItemConstants.MENU_NAME]: VALIDATION_STRING.trim(),
  [EMenuContainerAddNewItemConstants.ITEM_TYPE]: Yup.string().oneOf([
    MenuItemTypeEnum.INTERNAL,
    MenuItemTypeEnum.EXTERNAL,
  ]),
  [EMenuContainerAddNewItemConstants.URL]: Yup.string()
    .ensure()
    .when([EMenuContainerAddNewItemConstants.ITEM_TYPE], {
      is: MenuItemTypeEnum.EXTERNAL,
      then: VALIDATION_LINK,
    }),
});

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { isImageData } from '@prosv/core/types/bff/base/image.helpers';
import { Dictionary, DictionaryFullItem } from '@prosv/core/types/bff/dictionary';
import { CustomFields } from '@prosv/core/types/bff/dictionary/custom';
import { Pencil } from 'tabler-icons-react';

import { usePermission } from '@/hooks/usePermissions';

import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import { CUSTOM_FIELD_PREFIX } from './DictionaryItems.constants';
import { getConfigByCode } from './DictionaryItems.helpers';
import { DictionaryItemsState, DictionaryItemsStateControls } from './DictionaryItems.types';

import styles from './DictionaryItems.module.scss';

interface UseDictionaryItemsColumnsOptions<Dict extends string = string> {
  state: DictionaryItemsState<Dict>;
  controls: DictionaryItemsStateControls<Dict>;
  dictionary?: Dictionary;
}

export const useDictionaryItemsColumns = <Dict extends string = string>({
  dictionary,
  state: { sort },
  controls: { changeSort, setModal },
}: UseDictionaryItemsColumnsOptions<Dict>) => {
  const code = (useParams().code ?? '') as Dict;
  const canUpdate = usePermission('DICTIONARY_UPDATE');

  const columns = useMemo(() => {
    const getSort = (field: string) => (field === sort.field ? sort.direction : undefined);
    const config = getConfigByCode<Dict>(code);
    const additionalFields = dictionary?.additionalFields ?? [];

    const res = config.columns.map<ITableColumns<DictionaryFullItem<Dict>>>((field) => {
      // общие данные для всех полей
      const column: ITableColumns<DictionaryFullItem> = {
        field,
        title: config.labels[field] ?? field,
      };

      if (field !== 'comment') {
        column.sort = getSort(field);
        column.sortAction = () => changeSort(field);
      }

      if (field.startsWith(CUSTOM_FIELD_PREFIX)) {
        // дополнительное поле
        const fieldCode = field.replace(CUSTOM_FIELD_PREFIX, '') as keyof CustomFields<Dict>;
        const meta = additionalFields.find((m) => m.code === fieldCode);
        if (!meta) {
          return { ...column, render: () => '-' };
        }

        return {
          ...column,
          title: config.labels[field] ?? meta.title ?? field,
          render: (data) => {
            const rawVal = data.fields[fieldCode];
            const values = (Array.isArray(rawVal) ? rawVal : [rawVal]).map((value) => {
              switch (meta.type) {
                case 'bool':
                  return !!value ? 'Да' : 'Нет';

                case 'dictionary':
                  if (typeof value === 'object' && 'name' in value) {
                    return value.name;
                  }

                case 'file':
                  if (isImageData(value)) {
                    return (
                      <img
                        src={process.env.MEDIA_STORAGE_URL + value.config.previewConfig.path}
                        style={{ maxHeight: 24, maxWidth: 48, border: '1px solid grey' }}
                      />
                    );
                  } else {
                    return '-';
                  }

                default:
                  return `${value}`;
              }
            });

            return !meta.isMultiple ? (
              values[0]
            ) : (
              <div className={styles.multiValue}>
                {values.map((value, idx) => (
                  <span key={idx}>{value}</span>
                ))}
              </div>
            );
          },
        };
      } else {
        // Обычное поле
        switch (field) {
          case 'isActive':
            return { ...column, render: ({ isActive }) => (isActive ? 'Активно' : 'Неактивно') };

          case 'isReadonly':
            return { ...column, render: ({ isReadonly }) => (isReadonly ? 'Только чтение' : '-') };

          case 'createAt':
          case 'updateAt':
            return {
              ...column,
              render: ({ createAt }) => new Date(createAt.value).toLocaleDateString(),
            };
        }
      }

      return column;
    });

    res.push({
      title: '',
      field: 'actions',
      position: 'right',
      render: (item) =>
        canUpdate && (
          <Button
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            size='compact-sm'
            c='gray.4'
            onClick={() => setModal(item)}
          >
            <Pencil size={22} />
          </Button>
        ),
    });

    return res;
  }, [
    canUpdate,
    changeSort,
    code,
    dictionary?.additionalFields,
    setModal,
    sort.direction,
    sort.field,
  ]);

  return { columns };
};

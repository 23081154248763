import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import { getCookie } from '@/utils/cookie';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { CatalogTags } from '@/modules/catalogTags/pages/CatalogTags';
import { MediaStorage } from '@/modules/mediaStorage/pages';
import { NavigateWrapper } from '@/wrappers/NavigateWrapper/NavigateWrapper';

import { NavigateToPlugin } from '@/ui/components/NavigateToPlugin';
import { RequireAuth } from '@/ui/components/RequireAuth';
import { Dictionary } from '@/ui/containers/Dictionary';
import { DictionaryItems } from '@/ui/containers/DictionaryItems';
import { FeatureToggles } from '@/ui/containers/FeatureToggles';
import { PromotionCreation } from '@/ui/containers/PromotionCreation';

import { Auth } from './pages/Auth';
import { Cube } from './pages/Cube';
import { Menus } from './pages/Menus';
import { SelectionsPlaces } from './pages/SelectionPlaces';
import { KeyPersonsFill } from './ui/containers/KeyPersonsFill';
import { Layout } from './ui/Layout';

import '@/theme/global/index.scss';

import { AdfsOauthPage } from '@/pages/AdfsOauth';
import { BannerCreation } from '@/pages/BannerCreation';
import { BannerListing } from '@/pages/Banners/BannersListing';
import { GeneralSettings } from '@/pages/GeneralSettings';
import { GlobalSettings } from '@/pages/GlobalSettings';
import { Materials } from '@/pages/Materials/Materials';
import { MaterialsCreation } from '@/pages/MaterialsCreation';
import { Menu } from '@/pages/Menu';
import { MetaTagsAnyPage } from '@/pages/MetaTagsAnyPage';
import { MetaTagsCreation } from '@/pages/MetaTagsCreation';
import { News } from '@/pages/News';
import { NewsCreation } from '@/pages/NewsCreation';
import { ProjectDetailsEdit, ProjectDetailsNew, ProjectsList } from '@/pages/Projects';
import { Promotions } from '@/pages/Promotions';
import { QuestionDetailPage } from '@/pages/Questions/QuestionDetailPage';
import { QuestionsListingPage } from '@/pages/Questions/QuestionsListingPage';
import { Redirects } from '@/pages/Redirects';
import { ReviewsDetailPage } from '@/pages/Reviews/ReviewsDetailPage';
import { ReviewsListing } from '@/pages/Reviews/ReviewsListingPage';
import { Supports } from '@/pages/Support';
import { SupportCreateDetailsPage } from '@/pages/Support/details/SupportCreateDetailsPage';
import { SupportEditDetailsPage } from '@/pages/Support/details/SupportEditDetailsPage';
import { TemplateCreation } from '@/pages/TemplateCreation';
import { Templates } from '@/pages/Templates';
import { Umk } from '@/pages/Umk';
import { UmkDetail } from '@/pages/UmkDetail';
import { setUserToken } from '@/store/slices/auth/auth';
import { selectCurrentService, selectServices } from '@/store/slices/service/service';
import { theme } from '@/theme';

export const App: FC = () => {
  const dispatch = useAppDispatch();
  const token = getCookie('token');
  const service = useSelector(selectCurrentService);
  const services = useSelector(selectServices);
  const availablePlugins =
    service && services && services[service] ? services[service].plugins : [];

  useEffect(() => {
    dispatch(setUserToken(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<NavigateWrapper />}>
        <Route path='/auth' element={<Auth />} />

        <Route element={<Layout />}>
          <Route
            path='/'
            element={
              <RequireAuth>
                <NavigateToPlugin plugins={availablePlugins} />
              </RequireAuth>
            }
          />
          {/* TODO: удалить PROSV-1143 */}
          <Route
            path='/keypersons-fill'
            element={
              <RequireAuth>
                <KeyPersonsFill />
              </RequireAuth>
            }
          />
          <Route
            path='/dictionary/*'
            element={
              <RequireAuth>
                <Dictionary />
              </RequireAuth>
            }
          />
          <Route
            path='/dictionary/:code'
            element={
              <RequireAuth>
                <DictionaryItems />
              </RequireAuth>
            }
          />
          <Route
            path='/news'
            element={
              <RequireAuth permission='NEWS_GET_LIST'>
                <News />
              </RequireAuth>
            }
          />
          <Route
            path='/news/new'
            element={
              <RequireAuth permission='NEWS_CREATE'>
                <NewsCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/news/:id'
            element={
              <RequireAuth permission='NEWS_GET'>
                <NewsCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/tags'
            element={
              <RequireAuth permission='NEWS_GET_LIST'>
                <CatalogTags />
              </RequireAuth>
            }
          />
          <Route
            path='/banners'
            element={
              <RequireAuth permission='BANNER_GET_LIST'>
                <BannerListing />
              </RequireAuth>
            }
          />
          <Route
            path='/banners/new'
            element={
              <RequireAuth permission='BANNER_CREATE'>
                <BannerCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/banners/:id'
            element={
              <RequireAuth permission='BANNER_GET'>
                <BannerCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/materials'
            element={
              <RequireAuth permission='ARTICLE_GET_LIST'>
                <Materials />
              </RequireAuth>
            }
          />
          <Route
            path='/materials/new'
            element={
              <RequireAuth permission='ARTICLE_CREATE'>
                <MaterialsCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/materials/:id'
            element={
              <RequireAuth permission='ARTICLE_GET'>
                <MaterialsCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/selections-places'
            element={
              <RequireAuth>
                <SelectionsPlaces />
              </RequireAuth>
            }
          />
          <Route
            path='/questions'
            element={
              <RequireAuth permission='QUESTION_GET_LIST'>
                <QuestionsListingPage />
              </RequireAuth>
            }
          />
          <Route
            path='/questions/:id'
            element={
              <RequireAuth permission='QUESTION_GET'>
                <QuestionDetailPage />
              </RequireAuth>
            }
          />
          <Route
            path='/reviews'
            element={
              <RequireAuth permission='REVIEW_VIEW'>
                <ReviewsListing />
              </RequireAuth>
            }
          />
          <Route
            path='/reviews/:id'
            element={
              <RequireAuth permission='REVIEW_VIEW'>
                <ReviewsDetailPage />
              </RequireAuth>
            }
          />
          <Route
            path='/feature-toggles'
            element={
              // Исправить скрытие страницы тоглов
              <RequireAuth permission='ARTICLE_GET'>
                <FeatureToggles />
              </RequireAuth>
            }
          />
          <Route
            path='/general-settings'
            element={
              <RequireAuth permission='SETTING_GET_ALL'>
                <GeneralSettings />
              </RequireAuth>
            }
          />
          <Route
            path='/menu'
            element={
              <RequireAuth permission='MENU_GET_LIST'>
                <Menus />
              </RequireAuth>
            }
          />
          <Route
            path='/menu/:menuCode'
            element={
              <RequireAuth permission='MENU_GET'>
                <Menu />
              </RequireAuth>
            }
          />
          <Route
            path='/cube'
            element={
              <RequireAuth permission='MENU_GET_LIST'>
                <Cube />
              </RequireAuth>
            }
          />
          <Route
            path='/redirects'
            element={
              <RequireAuth permission='LIST_REDIRECTS'>
                <Redirects />
              </RequireAuth>
            }
          />
          <Route
            path='/templates-meta-tags'
            element={
              <RequireAuth permission='LIST_SEO_TEMPLATE'>
                <Templates />
              </RequireAuth>
            }
          />
          <Route
            path='/templates-meta-tags/:id'
            element={
              <RequireAuth permission='VIEW_SEO_TEMPLATE'>
                <TemplateCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/meta-tags-any-page'
            element={
              <RequireAuth permission='LIST_SEO_META'>
                <MetaTagsAnyPage />
              </RequireAuth>
            }
          />
          <Route
            path='/meta-tags-any-page/:id'
            element={
              <RequireAuth permission='VIEW_SEO_META'>
                <MetaTagsCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/file-upload'
            element={
              <RequireAuth permission='UPLOAD_FILE'>
                <MediaStorage />
              </RequireAuth>
            }
          />
          <Route
            path='/global-settings'
            element={
              <RequireAuth permission='SEO'>
                <GlobalSettings />
              </RequireAuth>
            }
          />
          <Route
            path='/support'
            element={
              <RequireAuth permission='FAQ_GET_LIST'>
                <Supports />
              </RequireAuth>
            }
          />
          <Route
            path='/support/:id'
            element={
              <RequireAuth permission='FAQ_GET'>
                <SupportEditDetailsPage />
              </RequireAuth>
            }
          />
          <Route
            path='/support/new'
            element={
              <RequireAuth permission='FAQ_CREATE'>
                <SupportCreateDetailsPage />
              </RequireAuth>
            }
          />
          <Route
            path='/projects'
            element={
              <RequireAuth permission='PROJECT_GET_LIST'>
                <ProjectsList />
              </RequireAuth>
            }
          />
          <Route
            path='/projects/new'
            element={
              <RequireAuth permission='PROJECT_CREATE'>
                <ProjectDetailsNew />
              </RequireAuth>
            }
          />
          <Route
            path='/projects/:id'
            element={
              <RequireAuth permission='PROJECT_VIEW'>
                <ProjectDetailsEdit />
              </RequireAuth>
            }
          />
          <Route
            path='/promotions'
            element={
              <RequireAuth permission='PROMOTION_GET_LIST'>
                <Promotions />
              </RequireAuth>
            }
          />
          <Route
            path='/promotions/new'
            element={
              <RequireAuth permission='PROMOTION_CREATE'>
                <PromotionCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/promotions/:id'
            element={
              <RequireAuth permission='PROMOTION_GET'>
                <PromotionCreation />
              </RequireAuth>
            }
          />
          <Route
            path='/umk'
            element={
              <RequireAuth permission='UMK_PAGE_GET_LIST'>
                <Umk />
              </RequireAuth>
            }
          />
          <Route
            path='/umk/:id'
            element={
              <RequireAuth permission='PROMOTION_CREATE'>
                <UmkDetail />
              </RequireAuth>
            }
          />
          <Route path='/adfs-oauth' element={<AdfsOauthPage />} />
          <Route path='*' element={<>404</>} />
        </Route>
      </Route>,
    ),
  );

  return (
    <MantineProvider theme={theme}>
      <Notifications position='top-right' />
      <RouterProvider router={router} />
    </MantineProvider>
  );
};

import React from 'react';
import { Box, Input, InputBase } from '@mantine/core';

import { FileLoader } from '@/ui/components/FileLoader';
import { useCompactPromoSnippet } from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/CompactPromoSnippet.hooks';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';

import { ECompactPromoSnippetFields } from './СompactPromoSnippet.constants';

export const CompactPromoSnippet = () => {
  const {
    handleSubmit,
    handleChangeImage,
    handleRemoveImage,
    imgData,
    snippetData,
    handleClose,
    form,
    sessionID,
    defaultValues,
    editorRef,
  } = useCompactPromoSnippet();

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Компактный промо-блок'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <Input.Wrapper
        label='Изображение'
        style={{ gridRow: 'span 2', gridColumn: 'span 6' }}
        withAsterisk
        {...form.getInputProps('file.id')}
      >
        <FileLoader
          savedFormFiles={imgData}
          onChange={handleChangeImage}
          onRemove={handleRemoveImage}
          sessionID={sessionID.current}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>

      <InputBase
        style={{ gridColumn: 'span 6' }}
        label='Alt'
        placeholder='Введите alt-текст изображения'
        {...form.getInputProps(ECompactPromoSnippetFields.IMAGE_ALT)}
      />

      <InputBase
        style={{ gridColumn: 'span 6' }}
        label='Title'
        placeholder='Введите заголовок изображения'
        {...form.getInputProps(ECompactPromoSnippetFields.IMAGE_TITLE)}
      />

      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gridColumn: 'span 12',
          gap: 24,
          minHeight: 450,
          maxHeight: 450,
          position: 'relative',
        }}
      >
        <TextEditor
          placeholder='Введите текст компактной акции'
          defaultTextValue={defaultValues?.description}
          editorRef={editorRef}
        />
      </Box>
    </SnippetModal>
  );
};

import { FC, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';

import {
  DragItemTypes,
  limitNestingItems,
  limitNestingLevel,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';

import { DroppableDropDown } from '../DroppableDropDown';

import { setMenuItemsAction } from '@/store/slices/menu/menuItems';
import { selectCurrentMenuType } from '@/store/slices/menu/menuTypes';
import { MenuTabSiteWrapper } from '@/ui/containers/MenuContainer/MenuTabSiteWrapper';

interface IProps {
  items: TTransformMenuItem[] | null;
}

export const MenuTabSite: FC<IProps> = ({ items }) => {
  const dispatch = useDispatch();
  const currentMenuType = useSelector(selectCurrentMenuType);

  const [menuItems, setMenuItems] = useState<TTransformMenuItem[] | null>(items);

  const limitNesting = (currentMenuType && limitNestingLevel[currentMenuType]) || 1;
  const limitItems = (currentMenuType && limitNestingItems[currentMenuType]) || 4;

  const findMenuItem = useCallback(
    (id: string) => {
      if (!menuItems) return;
      const menu = menuItems.filter((menuItem) => menuItem.id === id)[0];
      return {
        menu,
        indexes: [menuItems.indexOf(menu)],
      };
    },
    [menuItems],
  );

  const moveMenuItem = useCallback(
    (hoverId: string, oldIndexes: number[]) => {
      const [oldIndex] = oldIndexes;
      const item = findMenuItem(hoverId);
      if (!item || !menuItems) return;

      const { menu, indexes: currentIndexes } = item;
      const [currentIndex] = currentIndexes;
      setMenuItems(
        update(menuItems, {
          $splice: [
            [currentIndex, 1], // delete
            [oldIndex, 0, menu], // insert
          ],
        }).map((menuItem, index) => ({ ...menuItem, sortWeight: index + 1 })),
      );
    },
    [findMenuItem, menuItems, setMenuItems],
  );

  const [, drop] = useDrop(() => ({ accept: DragItemTypes.MENU }));

  useEffect(() => {
    dispatch(setMenuItemsAction(menuItems));
  }, [dispatch, menuItems]);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  return (
    <MenuTabSiteWrapper ref={drop}>
      {menuItems &&
        menuItems
          .filter((item) => !item.parentId)
          .map((item: TTransformMenuItem) => {
            return (
              <DroppableDropDown
                item={item}
                items={menuItems}
                limitNestingLevel={limitNesting}
                nestedElementLimit={limitItems}
                id={item.id}
                key={item.id}
                moveMenuItem={moveMenuItem}
                findMenuItem={findMenuItem}
              />
            );
          })}
    </MenuTabSiteWrapper>
  );
};

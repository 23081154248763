import { MouseEvent } from 'react';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import notify from '@/utils/notify';
import {
  setAddMenuItemsModalOpened,
  setDeleteMenuItemId,
  setEditMenuItem,
  setParentId,
} from '@/store/slices/menu/menuItems';
import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';

export const useDropItemActions = (
  item: TTransformMenuItem | MobileMenuParentItem | MobileMenuItem,
) => {
  const dispatch = useAppDispatch();

  const onAddAction = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setParentId(item.id));
    dispatch(setAddMenuItemsModalOpened(true));
  };

  const onCopyAction = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!('url' in item.setting)) return;

    const { url } = item.setting;
    try {
      await navigator.clipboard.writeText(url);
      notify({ message: `Текст "${url}" скопирован в буфер обмена`, type: 'success' });
    } catch (error) {
      notify({ message: `Ошибка при копировании текста "${url}"`, type: 'error' });
    }
  };

  const onEditAction = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setEditMenuItem({ item, parentId: item.parentId }));
    dispatch(setAddMenuItemsModalOpened(true));
  };

  const onDeleteAction = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setDeleteMenuItemId(item.id));
  };

  return { onAddAction, onCopyAction, onEditAction, onDeleteAction };
};

import { FC, MouseEvent } from 'react';
import { ActionIcon, Group } from '@mantine/core';
import { Copy, Pencil, Plus, Trash } from 'tabler-icons-react';

interface IProps {
  disableAddAction?: boolean;
  disableCopyAction?: boolean;
  actions: {
    onAddAction: (event: MouseEvent<HTMLButtonElement>) => void;
    onEditAction: (event: MouseEvent<HTMLButtonElement>) => void;
    onCopyAction: (event: MouseEvent<HTMLButtonElement>) => void;
    onDeleteAction: (event: MouseEvent<HTMLButtonElement>) => void;
  };
}

export const DropdownButtonItems: FC<IProps> = ({
  disableAddAction,
  disableCopyAction,
  actions: { onAddAction, onEditAction, onCopyAction, onDeleteAction },
}) => {
  return (
    <Group justify={'glex-end'} ml='auto'>
      <ActionIcon
        variant='transparent'
        name='Добавить'
        title='Добавить'
        onClick={onAddAction}
        disabled={disableAddAction}
      >
        <Plus size={20} color='gray' />
      </ActionIcon>

      <ActionIcon
        variant='transparent'
        name='Скопировать'
        title='Скопировать'
        onClick={onCopyAction}
        disabled={disableCopyAction}
      >
        <Copy size={20} color='gray' />
      </ActionIcon>

      <ActionIcon variant='transparent' name='Изменить' title='Изменить' onClick={onEditAction}>
        <Pencil size={20} color='gray' />
      </ActionIcon>

      <ActionIcon variant='transparent' name='Удалить' title='Удалить' onClick={onDeleteAction}>
        <Trash size={20} color='gray' />
      </ActionIcon>
    </Group>
  );
};

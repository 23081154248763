import * as Yup from 'yup';
import { VALIDATION_STRING } from '@/ui/containers/MenusContainer/MenuContainer.schema';
import { MenuItemTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { EMenuContainerAddNewItemConstants } from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem.constants';
import { VALIDATION_LINK } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

export enum AddOrEditChildMobileItemFields {
  MENU_NAME = 'name',
  ITEM_TYPE = 'itemType',
  URL = 'url',
  IS_NO_FOLLOW = 'isNofollow',
  IS_HIDE_FOR_ENTITIES = 'hideForEntities',
  ICON = 'icon',
}

export const CHILD_MOBILE_MENU_ITEMS_FORM = Yup.object().shape({
  [AddOrEditChildMobileItemFields.MENU_NAME]: VALIDATION_STRING.trim(),
  [AddOrEditChildMobileItemFields.ITEM_TYPE]: Yup.string().oneOf([
    MenuItemTypeEnum.INTERNAL,
    MenuItemTypeEnum.EXTERNAL,
  ]),
  [AddOrEditChildMobileItemFields.URL]: Yup.string()
    .required(validationTexts.REQUIRED)
    .ensure()
    .when([EMenuContainerAddNewItemConstants.ITEM_TYPE], {
      is: MenuItemTypeEnum.EXTERNAL,
      then: VALIDATION_LINK,
    }),
});

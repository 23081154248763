import { DictionariesConfigs, DictionaryConfig } from './DictionaryItems.types';

export const CUSTOM_FIELD_PREFIX = 'fields.';

export const DICTIONARY_ITEMS_LIMIT = 10;

/** Конфигурация словарей по умолчанию */
export const DEFAULT_CONFIG = {
  filters: ['query', 'isActive'],
  columns: ['name', 'isActive', 'code', 'createAt'],
  labels: {
    id: 'ID',
    name: 'Название',
    code: 'Код',
    comment: 'Описание',
    isActive: 'Активность',
    updateAt: 'Дата изменения',
    createAt: 'Дата создания',
    isReadonly: 'Только чтение',
    sort: 'Сортировка',
  },
} satisfies DictionaryConfig;

const createDictionaryConfig = <Dicts extends string>(
  config: DictionariesConfigs<Dicts>,
): DictionariesConfigs<Dicts> =>
  Object.entries<DictionaryConfig<any>>(config).reduce(
    (res, [dictCode, { filters, columns = [], labels = {}, ...rest }]) => ({
      ...res,
      [dictCode]: {
        columns,
        filters: filters ?? DEFAULT_CONFIG.filters,
        labels: { ...DEFAULT_CONFIG.labels, ...labels },
        ...rest,
      },
    }),
    config,
  );

/** Конфигурации для конкрентных словарей */
export const DICTIONARIES_CONFIGS = createDictionaryConfig({
  digitalservice: {
    columns: ['name', 'isActive', 'code', 'fields.targetAudience', 'createAt'],
  },

  keyperson: {
    filters: ['query', 'fields.region', 'isActive', 'fields.isMain'],
    columns: ['name', 'isActive', 'fields.region', 'fields.isMain', 'createAt'],
    labels: { name: 'Ф.И.О.' },
    additionalFieldsDefaults: { isMain: false },
  },
});

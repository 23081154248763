import { yupResolver } from '@mantine/form';
import { FormValidateInput } from '@mantine/form/lib/types';
import {
  CreateDictionaryItemRequest,
  Dictionary,
  DictionaryFullItem,
  UpdateDictionaryItemRequest,
} from '@prosv/core/types/bff/dictionary';
import { v4 as uuid_v4 } from 'uuid';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { replaceTempFile } from '@/api/file';

import { FIELDS } from './DictionaryItemForm.constants';
import { CommonSubmitData, DictionaryItemFormValues } from './DictionaryItemForm.types';

/** Преобразует данные дополнительных полей из API в значения формы */
export const getFieldsFormValues = (
  { isCustom, additionalFields }: Dictionary,
  fieldsData?: Record<string, unknown> | null,
) => {
  const fields = fieldsData ?? {};
  const res: Record<string, number | string | string[]> = {};

  if (!isCustom) return res;

  additionalFields.forEach(({ code: field, type, isMultiple }) => {
    isMultiple = isMultiple && type === 'dictionary';
    const val = fields[field];

    if (val === '' || val === undefined || val === null) {
      res[field] = isMultiple ? [] : '';
    } else if (type === 'int') {
      res[field] = Number(val);
    } else if (type === 'string') {
      res[field] = `${val}`;
    } else if (type === 'bool') {
      res[field] = val === true ? 'true' : 'false';
    } else if (type === 'file' || type === 'dictionary') {
      const vals = (Array.isArray(val) ? val : [val])
        .map((v) => typeof v === 'object' && 'id' in v && v.id)
        .filter((v) => !!v);

      res[field] = isMultiple ? vals : vals[0] ?? '';
    }
  });

  return res;
};

/** Преобразует значения формы в данные для запроса в API */
export const getCommonSubmitData = async (
  { isCustom, additionalFields }: Dictionary,
  { sort = 0, isActive, fields: fieldsVals, ...rest }: DictionaryItemFormValues,
  sessionId: string,
  item?: DictionaryFullItem | null,
): Promise<CommonSubmitData> => {
  const fields: Record<string, any> = {};

  if (isCustom) {
    for (const { code, type, isRequired } of additionalFields) {
      let val = fieldsVals[code];

      if (!val && !isRequired) {
        continue;
      }

      if (type === 'bool') {
        fields[code] = val === 'true';
      } else if (type === 'int') {
        fields[code] = Number(val);
      } else {
        if (type === 'file') {
          const prevVal = item?.fields?.[code]?.id;
          if (val && val !== prevVal) {
            const res = await replaceTempFile({ query: { sessionId, ids: [`${val}`] } });
            val = res?.payload.items[0].id ?? '';
          }
        }

        fields[code] = val;
      }
    }
  }

  const res: CommonSubmitData = {
    isActive: isActive === 'true',
    sort,
    fields,
    ...rest,
  };

  return res;
};

/** Преобразует значения формы в данные для запроса в API на создание нового элемента */
export const getCreateSubmitData = async (
  dictionary: Dictionary,
  values: DictionaryItemFormValues,
  sessionId: string,
): Promise<CreateDictionaryItemRequest> => ({
  ...(await getCommonSubmitData(dictionary, values, sessionId)),
  creatorId: uuid_v4(), // TODO: удалить PROSVNEW-8805
});

/** Преобразует значения формы в данные для запроса в API на изменение существующего элемента */
export const getUpdateSubmitData = async (
  dictionary: Dictionary,
  values: DictionaryItemFormValues,
  sessionId: string,
  item?: DictionaryFullItem | null,
): Promise<UpdateDictionaryItemRequest> => ({
  ...(await getCommonSubmitData(dictionary, values, sessionId, item)),
  editorId: uuid_v4(), // TODO: удалить PROSVNEW-8805
});

/** Создаёт правила валидации формы */
export const createFormValidate = ({
  isCustom,
  additionalFields = [],
}: Dictionary): FormValidateInput<DictionaryItemFormValues> => {
  const fieldsRules: AnyObject = {};

  if (isCustom) {
    additionalFields.forEach(({ code, type, isRequired, title, isMultiple }) => {
      let rule;

      if (type === 'bool') {
        rule = Yup.string().oneOf(['true', 'false']);
      } else if (type === 'int') {
        rule = Yup.number();
      } else if (type === 'dictionary' && isMultiple) {
        rule = Yup.array().of(Yup.string());
        if (isRequired) {
          rule = rule.min(1, `Поле "${title}" обязательно для заполнения`);
        }
      } else {
        rule = Yup.string();
      }

      if (isRequired) {
        rule = rule.required(`Поле "${title}" обязательно для заполнения`);
      }

      fieldsRules[code] = rule;
    });
  }

  return yupResolver(
    Yup.object({
      [FIELDS.name]: Yup.string().required('Поле "Название" обязательно для заполнения'),
      [FIELDS.code]: Yup.string().required('Поле "Код" обязательно для заполнения'),
      [FIELDS.comment]: Yup.string(),
      [FIELDS.isActive]: Yup.string().oneOf(['true', 'false']),
      [FIELDS.fields]: Yup.object(fieldsRules),
    }),
  );
};

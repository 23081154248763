export const URLS = {
  configuration: {
    getConfigurations: '/configurations',
    addConfigurations: '/configurations',
    getConfigurationsById: (id: string) => `/configurations/${id}`,
    putConfigurationsById: (id: string) => `/configurations/${id}`,
    delConfigurationsById: (id: string) => `/configurations/${id}`,
    getConfigurationsOptionsById: (id: string) => `/configurations/${id}/options`,
    addConfigurationsOptionsById: (id: string) => `/configurations/${id}/options`,
    putConfigurationsOptionsById: (id: string) => `/configurations/${id}/options`,
    putConfigurationOptionById: (confId: string, optId: string) =>
      `/configurations/${confId}/options/${optId}`,
    delConfigurationOptionById: (confId: string, optId: string) =>
      `/configurations/${confId}/options/${optId}`,
  },
  news: {
    getNewsList: () => `/v1/admin/news`,
    createNews: `/v1/admin/news`,
    getNews: (uuid: string) => `/v1/admin/news/${uuid}`,
    putNews: (uuid: string) => `/v1/admin/news/${uuid}`,
    deleteNews: (uuid: string) => `/v1/admin/news/${uuid}`,
    patchNewsViews: (uuid: string) => `/v1/admin/news/modified-views-count/${uuid}`,
    patchNewsActivity: (uuid: string) => `/v1/admin/news/modified-activity/${uuid}`,
    getNewsByLink: (site: string, urlCode: string) => `/v1/news/detail/${site}/${urlCode}`,
  },
  file: {
    uploadTempFile: () => '/v1/file/tmp/site-admin/upload',
    replaceTempFile: () => '/v1/file/tmp/replace',
  },
  dictionary: {
    getDictionaries: `/v1/dictionary`,
    getDictionaryItems: (code: string) => `/v1/dictionary/${code}`,
    getDictionaryItemsExtended: (code: string) => `/v1/dictionary/${code}/items`,
    getDictionaryItem: (code: string, id: string) => `/v1/dictionary/${code}/${id}`,
    createDictionaryItem: (code: string) => `/v1/dictionary/${code}`,
    updateDictionaryItem: (code: string, id: string) => `/v1/dictionary/${code}/${id}`,
    putDictionaryItems: (name: string | undefined) => `/v1/dictionary/${name}`,
  },
  featureToggles: {
    getFeatureToggles: `/v1/feature-toggle`,
    patchFeature: () => `/v1/feature-toggle`,
  },
  generalSettings: {
    getGeneralSettings: () => `/v1/settings`,
    patchGeneralSettings: () => `/v1/settings`,
  },
  mailsTemplate: {
    getMailsTemplates: '/mail-templates',
    delMailsTemplateById: (id: string) => `/mail-templates/${id}`,
    getMailTemplate: (id: string) => `/mail-templates/${id}`,
    putMailTemplate: (id: string) => `/mail-templates/${id}`,
  },
  media: {
    getMedia: '/media',
    getFolders: '/media/folders',
    addFolder: '/media/folders',
    getFolderInfo: (id: string) => `/media/folders/${id}`,
    delFolder: (id: string) => `/media/folders/${id}`,
    moveFolder: (id: string) => `/media/folders/${id}/move`,
    renameFolder: (id: string) => `/media/folders/${id}/rename`,
    addFile: '/media/files',
    addFiles: 'media/files/upload-list',
    getFileInfo: (id: string) => `/media/files/${id}`,
    delFile: (id: string) => `/media/files/${id}`,
    moveFile: (id: string) => `/media/files/${id}/move`,
    renameFile: (id: string) => `/media/files/${id}/rename`,
  },
  models: {
    getModels: '/models',
    getModel: (id: string) => `/models/${id}`,
    getModelSettings: (id: string) => `models/${id}/settings`,
    getModelFields: (id: string) => `models/${id}/fields`,
    getModelFieldValues: (modelId: string, fieldId: string) =>
      `models/${modelId}/fields/${fieldId}/values`,
    getModelElementsList: (id: string) => `models/${id}/elements/list`,
    addModelElement: (id: string) => `models/${id}/elements`,
    delModelElements: (id: string) => `models/${id}/elements/batch-delete`,
    getModelElement: (modelId: string, elementId: string) =>
      `models/${modelId}/elements/${elementId}`,
    changeModelElement: (modelId: string, elementId: string) =>
      `models/${modelId}/elements/${elementId}`,
    delModelElement: (modelId: string, elementId: string) =>
      `models/${modelId}/elements/${elementId}`,
    postExportModel: (modelId: string) => `models/${modelId}/export`,
    getExportModel: (modelId: string) => `models/${modelId}/export`,
    getDefaultKladr: `findById/address-list`,
  },
  menus: {
    getMenu: `/v1/admin/menu`,
    getMenusByType: (menuType: string) => `/v1/admin/menu/${menuType}`,
    putMenusByType: (menuType: string) => `/v1/admin/menu/${menuType}`,
    getMobileMenu: '/v1/admin/mobile/menu',
    putMobileMenu: '/v1/admin/mobile/menu',
    addMenus: '/v1/admin/menu',
    getMenuTypes: `/v1/admin/menu`,
    getMenusById: (id: string) => `/menus/${id}`,
    putMenusById: (id: string) => `/menus/${id}`,
    delMenuById: (id: string) => `/menus/${id}`,
  },
  cube: '/v1/admin/cube/menu',
  admin: {
    service: '/services',
  },
  catalogTags: {
    createCatalogTags: () => `/v1/admin/seo/catalog-tags`,
  },
  draft: {
    postDraft: 'v1/draft',
    getDraft: (id: string) => `v1/draft/${id}`,
    putDraft: (id: string) => `v1/draft/${id}`,
  },
  globalSettings: {
    getGlobalSettings: () => 'v1/settings/seo-setting',
  },
  redirects: {
    uploadFile: `/v1/file/site/upload`,
    getRedirects: '/v1/admin/seo/redirect',
    updateRedirect: (id: string) => `/v1/admin/seo/redirect/${id}`,
    deleteRedirect: (id: string) => `/v1/admin/seo/redirect/${id}`,
    postRedirectFileId: `/v1/admin/seo/redirect/parse-redirect-job`,
    getRedirectParseFile: (id: string) => `/v1/admin/seo/redirect/parse-redirect-job/${id}`,
  },
  metaTagsAnyPage: {
    getMetaTagsList: '/v1/admin/seo/meta',
    updateMetaTag: (id: string) => `/v1/admin/seo/meta/${id}`,
  },
  templates: {
    getTemplates: '/v1/admin/seo/template',
    updateTemplate: (id: string) => `/v1/admin/seo/template/${id}`,
  },
  catalog: {
    getProducts: '/v2/catalog/product',
    getProductSuggest: '/v2/catalog/product/product-suggest',
    getCatalogCategory: '/v1/catalog/category',
    getCatalogFilters: '/v1/catalog/filter',
    getProductsByIds: '/v2/catalog/product/product-by-ids',
    getSelections: '/v1/catalog/selection',
    getProduct: (id: string) => `/v2/catalog/product/${id}`,
  },
  banners: {
    listing: '/v1/admin/banner',
    postBanner: '/v2/admin/banner',
    getBanners: '/v1/admin/banner',
    getBanner: (id: string) => `/v1/admin/banner/${id}`,
    putBanner: (id: string) => `/v2/admin/banner/${id}`,
    putBannerStatus: (id: string) => `/v1/admin/banner/modified-status/${id}`,
    delBanner: (id: string) => `/v1/admin/banner/${id}`,
    getNumberOfClicks: (id: string) => `/v1/banner/click/${id}`,
    getNumberOfViews: (id: string) => `/v1/banner/view/${id}`,
    getAudioAttribute: '/v1/admin/banner/attributes/audio',
  },
  materials: {
    getMaterialsList: () => `/v1/admin/article`,
    getMaterial: (uuid: string) => `/v1/admin/article/${uuid}`,
    putMaterial: (uuid: string) => `/v1/admin/article/${uuid}`,
    deleteMaterial: (uuid: string) => `/v1/admin/article/${uuid}`,
    patchMaterialsViews: (uuid: string) => `/v1/admin/article/modified-views-count/${uuid}`,
    updateMaterialActive: (uuid: string) => `/v1/admin/article/toggle-active/${uuid}`,
    createMaterial: `/v1/admin/article`,
  },
  support: {
    listing: '/v1/admin/faq',
    detail: (id: string) => `/v1/admin/faq/${id}`,
  },
  promotions: {
    getPromotionsList: '/v1/admin/promotion',
    getPromotion: (id: string) => `/v1/admin/promotion/${id}`,
    createPromotion: `/v1/admin/promotion`,
    putPromotion: (uuid: string) => `/v1/admin/promotion/${uuid}`,
    patchPromotionActivity: (uuid: string) => `/v1/admin/promotion/modified-activity/${uuid}`,
    deletePromotion: (uuid: string) => `/v1/admin/promotion/${uuid}`,
  },
  project: {
    list: '/v1/admin/project',
    single: (uuid: string) => `/v1/admin/project/${uuid}`,
  },
  selectionsPlaces: {
    list: '/v1/admin/catalog/product/selection',
    get: (id: string) => `/v1/admin/catalog/product/selection/${id}`,
    patch: (id: string) => `/v1/admin/catalog/product/selection/${id}`,
  },
  umk: {
    list: '/v1/admin/catalog/umk/page',
    createUmk: '/v1/admin/catalog/umk/page',
    getUmk: (uuid: string) => `/v1/admin/catalog/umk/page/${uuid}`,
    updateUmk: (uuid: string) => `/v1/admin/catalog/umk/page/${uuid}`,
    deleteUmk: (uuid: string) => `/v1/admin/catalog/umk/page/${uuid}`,
    searchUmk: `/v1/admin/catalog/umk/by-type`,
  },
  audioApps: {
    list: '/v2/admin/audioapp/list',
  },
  fileService: {
    getFile: (id: string) => `/v1/file/${id}`,
  },
  review: {
    getDetail: (id: string) => `/v1/admin/reviews/${id}`,
    getList: '/v1/admin/reviews',
    export: '/v1/admin/reviews/export',
    patchStatus: (id: string) => `/v1/admin/reviews/${id}/status`,
    patchDetail: (id: string) => `/v1/admin/reviews/${id}`,
    exportReviews: '/v1/admin/question/export',
  },
  questions: {
    getList: '/v1/admin/question',
    getItem: (id: string) => `/v1/admin/question/${id}`,
    setStatus: '/v1/admin/question/status',
    patchAnswer: (answerId: string) => `/v1/admin/question/answer/${answerId}`,
    postAnswer: '/v1/admin/question/answer',
    export: '/v1/admin/question/export',
  },
  asyncJob: {
    getStatus: (jobId: string) => `/v1/async-job/${jobId}`,
  },
};

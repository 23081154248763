import { ChangeEvent, FC, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Group,
  Input,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  TextInput,
} from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { NewsDetailsPayload } from '@prosv/core/types';
import { IconCalendar } from '@tabler/icons';

import { transliteration } from '@/utils/transliteration';

import { usePermission } from '@/hooks/usePermissions';

import { FileLoader } from '@/ui/components/FileLoader';
import { Textarea } from '@/ui/components/Textarea';
import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';

import 'dayjs/locale/ru';

import {
  EActivityFieldValues,
  ENewsFormFields,
  selectDisplayOnPageOptions,
  selectDisplayOnSitesOptions,
} from './NewsForm.constants';
import { getImageData } from './NewsForm.helpers';
import { useNotifyOnFormError } from './NewsForm.hooks';
import { INewsFormFields } from './NewsForm.types';

import styles from './NewsForm.module.scss';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import { useUmkOptions } from '@/store/slices/umk/umk.hooks';

interface IProps {
  handleSubmit: (values: INewsFormFields, event: FormEvent<HTMLFormElement>) => void;
  form: UseFormReturnType<INewsFormFields>;
  sessionID: string;
  news: NewsDetailsPayload | null;
  isNewNews: boolean;
  setTrySave: (value: boolean) => void;
  trySaveNews: boolean;
  permissions: INewsCreationState['permissions'];
}

export const NewsForm: FC<IProps> = ({
  form,
  isNewNews,
  sessionID,
  news,
  setTrySave,
  trySaveNews,
  permissions: { canUpdate, canUpdateActivity },
}) => {
  const canCreateTag = usePermission('DICTIONARY_CREATE');

  const { data: tagSelectOptions } = useDictionaryOptions('tag');
  const { data: categorySelectOptions } = useDictionaryOptions('category');
  const { data: divisionSelectOptions } = useDictionaryOptions('division');
  const { data: companySelectOptions } = useDictionaryOptions('company');
  const { data: themeSelectOptions } = useDictionaryOptions('theme');
  const { data: eduLevelIdSelectOptions } = useDictionaryOptions('cnedulevelid');
  const { data: subjectSelectOptions } = useDictionaryOptions('cnsubject');
  const { data: directionIdSelectOptions } = useDictionaryOptions('cndirectionid');
  const { data: digitalServiceSelectOptions } = useDictionaryOptions('digitalservice');

  const umk = useUmkOptions(news?.umks);

  const savedFormFiles = getImageData(news?.image?.imageData);

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNewNews) {
      form.setFieldValue(
        ENewsFormFields.URL_CODE,
        `${transliteration(event.currentTarget.value, { onlyLower: true })}`,
      );
    }

    return form.getInputProps(ENewsFormFields.TITLE).onChange(event);
  };

  useNotifyOnFormError({ errors: form.errors, setTrySave, trySaveNews });

  return (
    <Box
      style={{
        gridTemplateColumns: 'repeat(12, 1fr)',
        display: 'grid',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TextInput
        withAsterisk
        label='Заголовок'
        style={{ gridColumn: 'span 8' }}
        placeholder='Введите заголовок новости'
        {...form.getInputProps(ENewsFormFields.TITLE)}
        onChange={handleChangeTitle}
        disabled={!canUpdate}
      />

      <Radio.Group
        label='Активность'
        withAsterisk
        {...form.getInputProps(ENewsFormFields.IS_ACTIVE)}
        style={{ gridColumn: 'span 4', justifyItems: 'end' }}
      >
        <Group>
          <Radio
            value={EActivityFieldValues.ACTIVE}
            label='Да'
            disabled={!(canUpdate || canUpdateActivity)}
          />
          <Radio
            value={EActivityFieldValues.NOT_ACTIVE}
            label='Нет'
            disabled={!(canUpdate || canUpdateActivity)}
          />
        </Group>
      </Radio.Group>

      <TextInput
        label='Символьный код URL'
        withAsterisk
        style={{ gridColumn: 'span 8' }}
        placeholder='Cсылка на новость'
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.URL_CODE)}
      />

      <DatePickerInput
        locale='ru'
        leftSection={<IconCalendar size={16} />}
        placeholder='Начало активности'
        label='Дата начала активности'
        withAsterisk
        style={{ gridColumn: 'span 4' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DATA_ACTIVE_FROM_DAYS)}
      />

      <Select
        label='Показывать на сайтах'
        data={selectDisplayOnSitesOptions}
        placeholder='Выберите сайты'
        withAsterisk
        disabled={!canUpdate}
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(ENewsFormFields.DISPLAY_ON_SITES)}
      />

      <MultiSelect
        label='Показывать на страницах'
        withAsterisk
        data={selectDisplayOnPageOptions}
        style={{ gridColumn: 'span 6' }}
        placeholder='Выберите сайты'
        disabled={!canUpdate}
        defaultValue={[selectDisplayOnPageOptions[0].value]}
        {...form.getInputProps(ENewsFormFields.TARGET_PAGES)}
      />

      <TimeInput
        style={{ gridColumn: 'span 6' }}
        label='Время начала активности'
        withAsterisk
        placeholder='чч:мм'
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DATA_ACTIVE_FROM_HOURS)}
      />

      <DatePickerInput
        locale='ru'
        leftSection={<IconCalendar size={16} />}
        placeholder='Окончание активности'
        label='Дата окончания активности'
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DATA_ACTIVE_TO)}
        style={{ gridColumn: 'span 6' }}
      />

      <NumberInput
        label='Время чтения (в минутах)'
        withAsterisk
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(ENewsFormFields.READING_TIME)}
        placeholder='Укажите время чтения'
        disabled={!canUpdate}
      />

      <Checkbox
        label='Сделать главной новостью'
        style={{ gridColumn: 'span 6', paddingTop: '22px' }}
        checked={form.values[ENewsFormFields.IS_MAIN_NEWS]}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.IS_MAIN_NEWS)}
      />

      <Textarea
        label='Краткое описание'
        placeholder='Введите краткое описание новости'
        className={styles.textarea}
        disabled={!canUpdate}
        maxLength={150}
        {...form.getInputProps(ENewsFormFields.SHORT_DESCRIPTION)}
      />

      <Input.Wrapper label='Изображение' style={{ gridColumn: 'span 6', gridRow: 'span 2' }}>
        <FileLoader
          disabled={!canUpdate}
          sessionID={sessionID}
          savedFormFiles={savedFormFiles}
          {...form.getInputProps(`image.${ENewsFormFields.IMAGE_ID}`)}
          onChange={(params) => {
            form.setFieldValue(`image.${ENewsFormFields.IMAGE_ID}`, params.id);
          }}
          onRemove={() => {
            form.setFieldValue(`image.${ENewsFormFields.IMAGE_ID}`, '');
          }}
        />
      </Input.Wrapper>

      <TextInput
        label='alt'
        style={{ gridColumn: 'span 6' }}
        placeholder='Введите alt'
        disabled={!canUpdate}
        {...form.getInputProps('image.alt')}
      />

      <TextInput
        label='title'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps('image.title')}
        disabled={!canUpdate}
        placeholder='Введите title'
      />

      <Select
        label='Категория'
        placeholder='Категория'
        data={categorySelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.CATEGORY)}
      />

      <Select
        label='Тема'
        placeholder='Тема'
        data={themeSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.THEME)}
      />

      <Select
        label='Дивизион'
        placeholder='Дивизион'
        data={divisionSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DIVISION)}
      />

      <Select
        label='Компания'
        placeholder='Компания'
        data={companySelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.COMPANY)}
      />

      <MultiSelect
        label='Теги'
        comboboxProps={{ position: 'top' }}
        placeholder='Компания'
        data={tagSelectOptions}
        style={{ gridColumn: 'span 5' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.TAGS)}
      />

      {canCreateTag && (
        <Button
          component={Link}
          to='/dictionary/tag'
          variant='white'
          target='_blank'
          style={{ paddingTop: '22px' }}
        >
          Добавить новый тег
        </Button>
      )}

      <MultiSelect
        label='Уровень образования'
        placeholder='Уровень образования'
        data={eduLevelIdSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.EDU_LEVEL)}
      />

      <MultiSelect
        label='Предмет'
        placeholder='Предмет'
        data={subjectSelectOptions}
        style={{ gridColumn: 'span 6' }}
        comboboxProps={{ position: 'top' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.SUBJECT)}
      />

      <MultiSelect
        label='Серия/Линия УМК'
        placeholder='Серия/Линия УМК'
        data={umk.options}
        style={{ gridColumn: 'span 6' }}
        comboboxProps={{ position: 'top' }}
        disabled={!canUpdate}
        searchValue={umk.input}
        onSearchChange={umk.setInput}
        searchable
        {...form.getInputProps(ENewsFormFields.SERIES_LINE_UMK)}
      />

      <MultiSelect
        label='Направление'
        placeholder='Направление'
        data={directionIdSelectOptions}
        style={{ gridColumn: 'span 6' }}
        comboboxProps={{ position: 'top' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DIRECTION)}
      />

      <MultiSelect
        label='Цифровой сервис'
        placeholder='Цифровой сервис'
        data={digitalServiceSelectOptions}
        style={{ gridColumn: 'span 6' }}
        comboboxProps={{ position: 'top' }}
        disabled={!canUpdate}
        {...form.getInputProps(ENewsFormFields.DIGITAL_SERVICE)}
      />
    </Box>
  );
};

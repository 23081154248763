import { FC, useMemo } from 'react';
import { Button, Group } from '@mantine/core';
import { AdditionalFieldMeta } from '@prosv/core/types/bff/dictionary';

import { CUSTOM_FIELD_PREFIX } from '../DictionaryItems.constants';

import { INPUT_SIZE } from './DictionaryItemsFilter.constants';
import { useDictionaryItemsFilter } from './DictionaryItemsFilter.hooks';
import { DictionaryItemsFilterProps } from './DictionaryItemsFilter.types';
import { FilterInput } from './FilterInput';

export const DictionaryItemsFilter: FC<DictionaryItemsFilterProps> = ({
  value,
  changeFilter,
  reset,
  isClear,
}) => {
  const { config, dictionary } = useDictionaryItemsFilter();

  const metas = useMemo(
    () =>
      (dictionary?.additionalFields ?? []).reduce<Record<string, AdditionalFieldMeta>>(
        (res, item) => ({
          ...res,
          [CUSTOM_FIELD_PREFIX + item.code]: item,
        }),
        {},
      ),
    [dictionary?.additionalFields],
  );

  return (
    <Group mb='md' align='flex-end'>
      {config.filters.map((field) => (
        <FilterInput
          key={field}
          field={field}
          meta={metas[field]}
          value={value[field]}
          onChange={(newValue) => changeFilter({ [field]: newValue })}
          size={INPUT_SIZE}
          config={config}
        />
      ))}

      {!isClear && !!reset && (
        <Button variant='light' size={INPUT_SIZE} onClick={reset}>
          Сбросить
        </Button>
      )}
    </Group>
  );
};

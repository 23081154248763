import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';

type GeneralItem = MobileMenuParentItem | MobileMenuItem;

/** Рекурсивно ищет элемент, возвращая путь к нему из индексов */
export const getIndexesPathForMobileMenuItem = (
  menuItems: GeneralItem[],
  targetId: string,
  indexes?: number[],
): number[] | null => {
  /** добавляет еще один индекс */
  const cloneIndexes = (currentIndex: number, indexes?: number[]) =>
    indexes ? [...indexes, currentIndex] : [currentIndex];

  for (let i = 0; i < menuItems.length; i++) {
    const item = menuItems[i];
    if (item.id === targetId) {
      // если нашли нужный элемент
      return cloneIndexes(i, indexes);
    } else if (item.items?.length) {
      // если не нашли нужный элемент, но есть вложенные, ищем по вложенным
      const newIndexes = cloneIndexes(i, indexes);
      const childrenData = getIndexesPathForMobileMenuItem(item.items, targetId, newIndexes);
      if (childrenData) return childrenData;
    }
  }

  return null;
};

/** Возвращает элемент по списку индексов */
export const getMobileMenuItemByIndexes = (menuItems: GeneralItem[], indexes: number[]) => {
  let item: GeneralItem | null = null;
  for (let i = 0; i < indexes.length; i++) {
    const index = indexes[i];
    const items: GeneralItem[] | null = item ? item.items : menuItems;
    if (!items) break; // Если нужно обращаться к вложенным элементам, а их нет, значит ничего не найдено
    item = items[index];
  }
  return item;
};

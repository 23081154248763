import { useCallback, useMemo, useState } from 'react';
import {
  DictionaryFullItem,
  DictionaryItemsExtendedRequest,
} from '@prosv/core/types/bff/dictionary';

import { DictionaryItemsFilterValue } from './DictionaryItemsFilter/DictionaryItemsFilter.types';
import { DICTIONARY_ITEMS_LIMIT } from './DictionaryItems.constants';

export const useDictionaryItemsState = <Dict extends string = string>() => {
  type Request = Required<DictionaryItemsExtendedRequest<Dict>>;
  type Sort = Required<Request['sort']>;
  type Pagination = Required<Request['pagination']>;
  type Defaults = { sort: Sort; pagination: Pagination };

  const defaults = useMemo<Defaults>(
    () => ({
      sort: { field: 'name' as Sort['field'], direction: 'asc' },
      pagination: { offset: 0, limit: DICTIONARY_ITEMS_LIMIT },
    }),
    [],
  );

  const [filter, setFilter] = useState<DictionaryItemsFilterValue<Dict>>({});
  const [sort, setSort] = useState(defaults.sort);
  const [pagination, setPagination] = useState(defaults.pagination);

  const [modal, setModal] = useState<DictionaryFullItem<Dict> | boolean>(false);

  const state = useMemo(() => {
    const isFilterClear = !Object.values(filter).filter((val) => !!val).length;

    const isSortClear =
      sort.field === defaults.sort.field && sort.direction === defaults.sort.direction;

    const isPaginationClear =
      pagination.offset === defaults.pagination.offset &&
      pagination.limit === defaults.pagination.limit;

    return {
      isClear: isFilterClear && isSortClear && isPaginationClear,
      filter,
      sort,
      pagination,
      page: Math.floor(pagination.offset / pagination.limit + 1),
      isOpen: !!modal,
      item: typeof modal === 'boolean' ? undefined : modal,
    };
  }, [defaults, filter, modal, pagination, sort]);

  const changeFilter = useCallback(
    (value: Partial<DictionaryItemsFilterValue> | null | false) =>
      setFilter((prev) => (!value ? {} : { ...prev, ...value })),
    [],
  );

  const changeSort = useCallback(
    (field: Sort['field']) =>
      setSort((prev) => ({
        field,
        direction: prev.field !== field || prev.direction === 'desc' ? 'asc' : 'desc',
      })),
    [],
  );

  const changePage = useCallback(
    (page: number, limit?: number) =>
      setPagination((prev) => {
        limit = Math.max(limit ?? prev.limit, DICTIONARY_ITEMS_LIMIT);
        const offset = Math.max(0, page - 1) * limit;
        return { limit, offset };
      }),
    [],
  );

  const changeLimit = useCallback((limit: number) => changePage(1, limit), [changePage]);

  const reset = useCallback(() => {
    changeFilter(null);
    setSort({ ...defaults.sort });
    setPagination({ ...defaults.pagination });
  }, [changeFilter, defaults.pagination, defaults.sort]);

  return {
    state,
    controls: { changeFilter, changeSort, changePage, changeLimit, reset, setModal },
  };
};

import { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Checkbox, Group, Modal, Radio, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { ModalConfirm } from '@/ui/components/ModalConfirm';
import {
  MenuContextEnum,
  MenuItemTypeEnum,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TEditMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';
import {
  EAddNewItemConfirmationTexts,
  EMenuContainerAddNewItemConstants,
} from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem.constants';
import { getNewItemInitialValues } from '@/ui/containers/MenuContainer/modals/AddOrEditItem/AddOrEditItem.helpers';
import { NEW_MENU_ITEM_FORM } from '@/ui/containers/MenusContainer/MenuContainer.schema';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

import styles from './AddOrEditItem.module.scss';

interface IProps {
  context: MenuContextEnum;
  isOpened: boolean;
  editItem?: TEditMenuItem;
  parentId?: string | null;
  visibilityParent: boolean;
  onClose: () => void;
  onChange: (values: Omit<TAddOrEditItem, 'id'> | TEditMenuItem, parentId: string | null) => void;
}

export const AddOrEditItem: FC<IProps> = ({
  context,
  parentId,
  visibilityParent,
  editItem,
  isOpened,
  onClose,
  onChange,
}) => {
  const addNewItemForm = useForm({
    initialValues: getNewItemInitialValues(editItem),
    validate: yupResolver(NEW_MENU_ITEM_FORM),
  });

  const [isOpenedConfirmation, setIsOpenedConfirmation] = useState<boolean>(false);

  const isCorpSite = context === MenuContextEnum.CORP;

  const handlePopup = () => {
    if (addNewItemForm.isValid()) {
      addNewItemForm.setFieldValue(EMenuContainerAddNewItemConstants.IS_VISIBLE, false);
      setIsOpenedConfirmation(!isOpenedConfirmation);
    } else {
      addNewItemForm.validate();
    }
  };

  const handleSubmit = (values: Omit<TAddOrEditItem, 'id'>) => {
    const urlValue = !values.url?.trim() ? '#' : values.url?.trim();

    if (addNewItemForm.isValid()) {
      if (editItem) {
        onChange({ ...editItem, ...values, url: urlValue }, parentId || null);
      } else {
        onChange(values, parentId || null);
      }

      addNewItemForm.reset();
    } else {
      addNewItemForm.validate();
    }
    onClose();
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      addNewItemForm.setFieldValue(EMenuContainerAddNewItemConstants.IS_VISIBLE, true);
    } else {
      setIsOpenedConfirmation(true);
    }
  };

  return (
    <Modal
      centered
      padding='lg'
      opened={isOpened}
      onClose={onClose}
      title={
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Text fz={24} ta='left' fw={400}>
            {editItem ? 'Редактирование пункта меню' : 'Добавление пункта меню'}
          </Text>
        </Box>
      }
      size={640}
      withCloseButton
    >
      <Box className={styles.content}>
        <form onSubmit={addNewItemForm.onSubmit((values) => handleSubmit(values))}>
          <Box className={styles.titleBox}>
            <TextInput
              withAsterisk
              label='Название'
              placeholder='Введите название'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.MENU_NAME)}
            />
            <Checkbox
              label='Выделить жирным'
              checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.TEXT_BOLD]}
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.TEXT_BOLD)}
            />
          </Box>

          <Radio.Group
            className={styles.typeRadioGroup}
            value={addNewItemForm.values[EMenuContainerAddNewItemConstants.ITEM_TYPE]}
            label='Тип пункта'
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.ITEM_TYPE)}
          >
            <Group>
              <Radio value={MenuItemTypeEnum.INTERNAL} label='Страница сайта' />
              <Radio value={MenuItemTypeEnum.EXTERNAL} label='Внешняя ссылка' />
            </Group>
          </Radio.Group>

          {addNewItemForm.values[EMenuContainerAddNewItemConstants.ITEM_TYPE] ===
          MenuItemTypeEnum.INTERNAL ? (
            <TextInput
              label='Url'
              placeholder='Введите ссылку'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.URL)}
            />
          ) : (
            <TextInput
              label='Url'
              placeholder='Введите внешнюю ссылку'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.URL)}
            />
          )}

          <Box className={styles.visibilityBox}>
            {isCorpSite ? (
              <Checkbox
                label='Отображать пункт на КС'
                checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_VISIBLE]}
                onChange={handleCheckboxChange}
                disabled={!visibilityParent}
              />
            ) : (
              <Checkbox
                label='Отображать пункт на ПС'
                checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_VISIBLE]}
                onChange={handleCheckboxChange}
                disabled={!visibilityParent}
              />
            )}
          </Box>

          <Checkbox
            label='Добавить атрибут nofollow'
            checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_NO_FOLLOW]}
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.IS_NO_FOLLOW)}
          />

          <Checkbox
            label='Поместить в нижнюю часть бургера на десктопе'
            checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_BURGER_BOTTOM]}
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.IS_BURGER_BOTTOM)}
          />

          <Group justify='flex-end' pt={32}>
            <Button type='submit' px={60} fz={16}>
              Подтвердить
            </Button>
          </Group>
        </form>

        <ModalConfirm
          title={EAddNewItemConfirmationTexts.TITLE}
          text={
            isCorpSite
              ? EAddNewItemConfirmationTexts.REMOVE_FROM_CORP_SITE
              : EAddNewItemConfirmationTexts.REMOVE_FROM_USER_SITE
          }
          confirmHandler={handlePopup}
          opened={isOpenedConfirmation}
          onClose={() => setIsOpenedConfirmation(!isOpenedConfirmation)}
        />
      </Box>
    </Modal>
  );
};

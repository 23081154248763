import { CSSProperties, forwardRef, MouseEvent, ReactElement, useCallback } from 'react';
import { Box, Title } from '@mantine/core';
import { MainMenu } from './MainMenu';
import { setAddMenuItemsModalOpened, setParentId } from '@/store/slices/menu/menuItems';
import { useDispatch } from 'react-redux';

interface Props {
  children: ReactElement[] | null;
  wrapperStyle?: CSSProperties;
}

export const MenuTabSiteWrapper = forwardRef<HTMLDivElement, Props>(
  ({ wrapperStyle, children }, dropRef) => {
    const dispatch = useDispatch();

    const addItemHandler = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(setParentId(null));
        dispatch(setAddMenuItemsModalOpened(true));
      },
      [dispatch],
    );

    return (
      <Box
        ref={dropRef}
        style={{
          padding: '24px 0 34px 0',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: 'calc(100% - 100px)',
          maxHeight: '100%',
          overflow: 'scroll',
          ...wrapperStyle,
        }}
      >
        <Title order={5} c='dimmed'>
          Перетащите элементы меню, чтобы изменить их порядок
        </Title>
        <MainMenu name='prosv.ru' addItemHandler={addItemHandler}>
          {children}
        </MainMenu>
      </Box>
    );
  },
);

import { FC } from 'react';
import { Textarea as TextareaBase } from '@mantine/core';

import { TextareaProps } from './Textarea.types';

import styles from './Textarea.module.scss';

export const Textarea: FC<TextareaProps> = ({
  className,
  textAreaClassName,
  maxLength,
  hideCounter,
  value,
  ...props
}) => {
  const showBottomBar: boolean = !!maxLength && !hideCounter;
  const valueLength = typeof value === 'string' ? value.length : 0;

  return (
    <div className={cn(styles.root, className)}>
      <TextareaBase {...props} className={textAreaClassName} maxLength={maxLength} value={value} />

      {showBottomBar && (
        <div className={styles.bottomBar}>
          <span>&nbsp;</span>
          <span className={styles.counter}>
            {valueLength}&nbsp;/&nbsp;{maxLength}
          </span>
        </div>
      )}
    </div>
  );
};

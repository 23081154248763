import { FC } from 'react';
import { Text, Title } from '@mantine/core';

interface IPageHeaderTitle {
  title: string;
  subTitle?: string;
}

const PageHeaderTitle: FC<IPageHeaderTitle> = ({ title, subTitle }) => {
  return (
    <Title order={1}>
      {title}
      {subTitle && <Text c='gray'>{subTitle}</Text>}
    </Title>
  );
};

export default PageHeaderTitle;

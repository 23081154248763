import { FC, useMemo } from 'react';
import { Box, Modal, Text } from '@mantine/core';
import styles from './AddOrEditMobileItem.module.scss';
import {
  getMenuItemLevels,
  getModalTitle,
} from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/AddOrEditMobileItem.helpers';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { ParentContent } from './contents/ParentContent';
import {
  getIndexesPathForMobileMenuItem,
  getMobileMenuItemByIndexes,
} from '@/ui/containers/MenuContainer/MobileMenuTabSite/MobileMenuTabSite.helpers';
import { AddParentMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ParentContent/ParentContent.types';
import { ChildContent } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent';
import { AddChildMobileItem } from '@/ui/containers/MenuContainer/modals/AddOrEditMobileItem/contents/ChildContent/ChildContent.types';
import { isMobileMenuParentItem } from '@prosv/core/types/bff/menu/helpers';

interface IProps {
  menuItems: MobileMenuParentItem[] | null;
  parentId: string | null;
  isOpened: boolean;
  editItem?: MobileMenuParentItem | MobileMenuItem;
  onClose: () => void;
  onEdit?: (
    values: AddParentMobileItem | AddChildMobileItem,
    editedItem: MobileMenuParentItem | MobileMenuItem,
  ) => void;
  onChange?: (values: AddParentMobileItem | AddChildMobileItem, parentId: string | null) => void;
}

export const AddOrEditMobileItem: FC<IProps> = ({
  menuItems,
  editItem,
  isOpened,
  onClose,
  onEdit,
  onChange,
  parentId,
}) => {
  const parent = useMemo(() => {
    if (!parentId || !menuItems) return null;
    const indexes = getIndexesPathForMobileMenuItem(menuItems, parentId);
    if (!indexes) return null;
    return getMobileMenuItemByIndexes(menuItems, indexes);
  }, [menuItems, parentId]);
  const itemLevel = getMenuItemLevels(parent || null);

  const handleSubmit = (
    values: AddParentMobileItem | AddChildMobileItem,
    resetForm: () => void,
  ) => {
    if (editItem) {
      onEdit?.(values, editItem);
    } else {
      onChange?.(values, parentId);
    }
    resetForm();
    onClose();
  };

  return (
    <Modal
      centered
      padding='lg'
      opened={isOpened}
      onClose={onClose}
      title={
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Text fz={24} ta='left' fw={400}>
            {getModalTitle(itemLevel, !editItem)}
          </Text>
        </Box>
      }
      size={640}
      withCloseButton
    >
      <Box className={styles.content}>
        {!parentId ? (
          <ParentContent
            editItem={editItem && isMobileMenuParentItem(editItem) ? editItem : undefined}
            onSubmit={handleSubmit}
          />
        ) : (
          <ChildContent
            level={itemLevel}
            editItem={editItem && isMobileMenuParentItem(editItem) ? undefined : editItem}
            onSubmit={handleSubmit}
          />
        )}
      </Box>
    </Modal>
  );
};

import { useCallback, useMemo } from 'react';
import { CreateDictionaryItemRequest, DictionaryFullItem } from '@prosv/core/types/bff/dictionary';
import { v4 as uuid_v4 } from 'uuid';

import { transliteration } from '@/utils/transliteration';

import { peopleList } from './constants/people';
import { regionsMap } from './constants/regions';

import {
  useCreateDictionaryItem,
  useDictionaryItems,
} from '@/store/slices/dictionary/dictionary.hooks';

export const useKeyPersonsFill = () => {
  const [create] = useCreateDictionaryItem();
  const { data: regionsData, isLoading } = useDictionaryItems({ code: 'region' });

  const regionsByCode = useMemo(
    () =>
      (regionsData?.items ?? []).reduce<Record<string, DictionaryFullItem>>(
        (res, region) => ({ ...res, [region.code]: region }),
        {},
      ),
    [regionsData?.items],
  );

  const fillRegions = useCallback(async () => {
    console.log('fill regions...', regionsMap.length);

    const creatorId = uuid_v4();
    const regions = Object.entries(regionsMap).map(([code, name]) => ({ code, name }));
    for (const { code, name } of regions) {
      console.log(code, name);
      try {
        const res = await create({
          code: 'region',
          params: {
            code,
            name,
            comment: '',
            isActive: true,
            sort: 0,
            fields: {},
            creatorId,
          },
        }).unwrap();

        console.log('success: ', res);
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }, [create]);

  const fillKeyPersons = useCallback(async () => {
    console.log('fill keypersons...', peopleList.length);

    const creatorId = uuid_v4();
    for (const { name, email, phone, job, weight, avatar, regions } of peopleList) {
      try {
        const fields: CreateDictionaryItemRequest['fields'] = {
          region: regions.map((code) => regionsByCode[code]?.id).filter((id) => !!id),
          email,
          phone: phone[0]?.value ?? '',
          post: job,
          isMain: false,
        };

        if (avatar) {
          const m = avatar.match(/.*\/([^\/]+)-resized.webp/);
          const file = m && m[1];
          if (file) {
            fields.file = file;
          }
        }

        const res = await create({
          code: 'keyperson',
          params: {
            code: transliteration(name),
            name,
            comment: '',
            isActive: true,
            sort: weight,
            fields,
            creatorId,
          },
        }).unwrap();
        console.log('success: ', res);
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }, [create, regionsByCode]);

  return { fillRegions, fillKeyPersons, isLoading };
};

import { FC, useMemo } from 'react';

import { DictionaryMultiSelect } from '@/ui/components/DictionaryMultiSelect';

import { CUSTOM_FIELD_PREFIX } from '../../DictionaryItems.constants';

import { BoolInput } from './BoolInput';
import { FilterInputProps } from './FilterInput.types';
import { QueryInput } from './QueryInput';

export const FilterInput: FC<FilterInputProps> = ({
  field,
  config: { labels },
  meta,
  value: valueProp,
  ...rest
}) => {
  const [singleValue, arrayValue] = useMemo(() => {
    // для того, чтобы нормально сбрасывались select'ы
    const value = valueProp ? valueProp : null;
    // значния single/array
    return Array.isArray(value) ? ['', value] : [value, []];
  }, [valueProp]);

  // базовые свойства для всех типов полей
  const props = {
    label: field in labels ? (labels as Record<string, string>)[field] : '',
    ...rest,
  };

  if (field.startsWith(CUSTOM_FIELD_PREFIX)) {
    // дополнительное поле
    if (!meta) {
      return null;
    } else if (!props.label) {
      props.label = meta.title;
    }

    switch (meta.type) {
      case 'int':
      case 'string':
        return <QueryInput {...props} value={singleValue} />;
      case 'bool':
        return <BoolInput {...props} value={singleValue} />;
      case 'dictionary':
        return (
          <DictionaryMultiSelect
            clearable
            dictionary={meta.dictionaryType ?? ''}
            placeholder='Выберите значение'
            value={arrayValue}
            {...props}
          />
        );
    }
  } else {
    // обычное поле
    switch (field) {
      case 'query':
        return <QueryInput {...props} label='Поиск' value={singleValue} />;
      case 'isActive':
        return <BoolInput {...props} value={singleValue} />;
    }
  }

  throw new Error(`Фильтрация элементов словаря по этому полю не поддерживается: ${field}`);
};

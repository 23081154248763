import { DictionaryItemFormValues } from './DictionaryItemForm.types';

export enum FIELDS {
  name = 'name',
  code = 'code',
  comment = 'comment',
  sort = 'sort',
  isActive = 'isActive',
  fields = 'fields',
}

export const DEFAULT_VALUES: DictionaryItemFormValues = {
  code: '',
  name: '',
  comment: '',
  isActive: 'false',
  sort: 0,
  fields: {},
};

import { FC, useEffect, useMemo, useState } from 'react';
import { TextInput } from '@mantine/core';
import { debounce } from 'lodash';

import { INPUT_DEBOUNCE } from './QueryInput.constants';
import { QueryInputProps } from './QueryInput.types';

export const QueryInput: FC<QueryInputProps> = ({
  value,
  onChange,
  placeholder = 'Введите запрос',
  ...rest
}) => {
  const [localValue, setLocalValue] = useState<string>(value ?? '');
  const [isChanged, setChanged] = useState(false);

  const onChangeDebounced = useMemo(
    () =>
      debounce((newValue: string) => {
        onChange(newValue);
        setChanged(false);
      }, INPUT_DEBOUNCE),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isChanged) {
      setLocalValue(value ?? '');
    }
  }, [isChanged, value]);

  useEffect(() => {
    setChanged(true);
    onChangeDebounced(localValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  return (
    <TextInput
      value={localValue}
      onChange={(event) => setLocalValue(event.currentTarget.value)}
      placeholder={placeholder}
      {...rest}
    />
  );
};

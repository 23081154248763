import { AddChildMobileItem, AddOrEditChildMobileItem } from './ChildContent.types';
import { AddOrEditChildMobileItemFields } from './ChildContent.constants';
import { MenuItemTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { ISimpleFile } from '@/ui/components/FileLoader/FileLoader.types';
import { replaceTempFile } from '@/api';
import notify from '@/utils/notify';

export const getMenuImageData = (imageHref?: string): ISimpleFile | null => {
  if (imageHref) {
    return {
      name: imageHref,
      id: imageHref,
      preview: imageHref,
    };
  }
  return null;
};

export const getFormInitialData = (withIcon: boolean, defaultValues?: AddOrEditChildMobileItem) => {
  return {
    [AddOrEditChildMobileItemFields.MENU_NAME]: defaultValues?.name || '',
    [AddOrEditChildMobileItemFields.ITEM_TYPE]:
      defaultValues?.setting.itemType || MenuItemTypeEnum.INTERNAL,
    [AddOrEditChildMobileItemFields.URL]: defaultValues?.setting.url || '',
    [AddOrEditChildMobileItemFields.IS_NO_FOLLOW]: defaultValues?.setting.isNofollow || false,
    [AddOrEditChildMobileItemFields.IS_HIDE_FOR_ENTITIES]: !(defaultValues
      ? defaultValues.setting.isShowForLegalEntity
      : true),
    [AddOrEditChildMobileItemFields.ICON]: withIcon
      ? getMenuImageData(defaultValues?.setting.icon)
      : null,
  };
};

export const getFinalImageLink = async (
  icon: AddChildMobileItem['icon'],
  sessionID: string,
): Promise<ISimpleFile | null> => {
  // Нет иконки
  if (!icon || !icon.id) return null;
  // Иконку не требуется дополнительно загружать
  if (icon.id === icon.name || icon.id === icon.preview) return icon;

  try {
    const loadedImageData = await replaceTempFile({
      query: {
        sessionId: sessionID,
        ids: [icon.id],
      },
    });
    if (!loadedImageData) throw new Error();
    const imageFile = loadedImageData.payload.items[0];

    return {
      name: imageFile.name,
      id: imageFile.id,
      preview: process.env.MEDIA_STORAGE_URL + imageFile.config.previewConfig.path,
    };
  } catch {
    notify({
      message: 'Произошла ошибка при загрузке изображения',
      type: 'error',
    });
    return null;
  }
};

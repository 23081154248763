import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Center,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { nanoid } from 'nanoid';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import { getPages } from '@/utils/getPages';

import { cellPosition } from '@/ui/components/CustomTable/CustomTable.constants';
import { ITableProps } from '@/ui/components/CustomTable/CustomTable.types';
import { DraggbleTableRow } from '@/ui/components/CustomTable/DraggbleTableRow';
import { TableRow } from '@/ui/components/CustomTable/TableRow';
import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';
import { itemsPerPageOptions } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import styles from './CustomTable.module.scss';

export const CustomTable = (props: ITableProps) => {
  const {
    columns,
    data = [],
    status,
    total = 0,
    limit,
    onChangePagination,
    onChangeLimit,
    noData,
    noFooter,
    currentPage,
    onRenderClick,
    draggable,
    moveItem,
  } = props;
  const isLoading = ['loading', 'init'].includes(status);
  const theme = useMantineTheme();

  const [paginationWidth, setPaginationWidth] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  const isShowPagination = !!(total && getPages(total, limit || 0) > 1);

  useEffect(() => {
    if (ref.current) {
      setPaginationWidth(ref.current?.offsetWidth);
    }
  }, [ref.current?.offsetWidth]);

  return (
    <>
      <ScrollArea h='100%'>
        <Table highlightOnHover verticalSpacing='xs' fz='sm'>
          <Table.Thead className='sticky'>
            <Table.Tr>
              {draggable && <Table.Th></Table.Th>}
              {columns?.map((column) => {
                const position =
                  cellPosition[(column?.position || 'left') as keyof typeof cellPosition];

                const Icon = column.sort
                  ? column.sort === 'asc'
                    ? ChevronUp
                    : ChevronDown
                  : Selector;

                return (
                  <Table.Th
                    onClick={() => (column.sortAction ? column.sortAction() : undefined)}
                    key={column.field + nanoid()}
                  >
                    <div
                      style={{
                        ...column.style,
                        ...(column.sortAction
                          ? { display: 'flex', gap: 4, cursor: 'pointer' }
                          : {}),
                      }}
                    >
                      {column.sortAction ? (
                        <Icon size={20} color={theme.colors.greyDefault[9]} />
                      ) : (
                        ''
                      )}
                      <div style={{ width: 'fit-content', ...position }}>
                        {typeof column.title === 'string' ? (
                          <TableColumnTitle>{column.title}</TableColumnTitle>
                        ) : (
                          column.title
                        )}
                      </div>
                    </div>
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            <LoadingOverlay zIndex={1} visible={isLoading} />
            {data.length > 0 ? (
              data.map((row, i) => {
                return draggable ? (
                  <DraggbleTableRow
                    key={i + nanoid()}
                    columns={columns}
                    row={row}
                    status={status}
                    index={i}
                    onRenderClick={onRenderClick}
                    moveItem={moveItem!}
                    id={row.id}
                  />
                ) : (
                  <TableRow
                    key={i + nanoid()}
                    row={row}
                    columns={columns}
                    onRenderClick={onRenderClick}
                  />
                );
              })
            ) : (
              <>
                {status === 'loaded' && (
                  <Center style={{ height: '80%', position: 'absolute', width: '100%' }}>
                    <Text ta='center'>{noData || '-'}</Text>
                  </Center>
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>

      {!noFooter && (
        <Box className={styles.footer}>
          {total > 0 && (
            <Box className={styles.total}>
              <Text size='md' c='gray'>
                Всего:
              </Text>
              &nbsp;
              <Text size='md'>{total}</Text>
            </Box>
          )}

          <Box ref={ref} className={styles.pagesBlock}>
            {total > (limit || 0) && isShowPagination && (
              <Pagination
                className={styles.pagination}
                total={getPages(total || 0, limit || 0)}
                value={currentPage}
                onChange={(page) => onChangePagination?.(page, limit || 0)}
              />
            )}
            {onChangeLimit && (
              <Select
                className={styles.select}
                ml='auto'
                size='sm'
                defaultValue={String(limit)}
                allowDeselect={false}
                data={itemsPerPageOptions}
                onChange={(value) => onChangeLimit?.(Number(value))}
              />
            )}
            <Box
              style={{
                position: 'absolute',
                height: '100%',
                width: `${paginationWidth}px`,
                right: 0,
                top: 0,
                background: isLoading ? 'rgba(255,255,255, 0.6)' : 'red',
                zIndex: isLoading ? 0 : -1,
                cursor: isLoading ? 'not-allowed' : 'unset',
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

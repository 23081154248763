import * as Yup from 'yup';
import { VALIDATION_STRING } from '@/ui/containers/MenusContainer/MenuContainer.schema';
import { validationTexts } from '@/constants/validationTexts';

export enum AddOrEditParentMobileItemFields {
  MENU_NAME = 'name',
  MENU_BACKGROUND = 'background',
  IS_HIDE_FOR_ENTITIES = 'hideForEntities',
}

export const PARENT_MOBILE_MENU_ITEMS_FORM = Yup.object().shape({
  [AddOrEditParentMobileItemFields.MENU_NAME]: VALIDATION_STRING.trim(),
  [AddOrEditParentMobileItemFields.MENU_BACKGROUND]: Yup.string()
    .nullable()
    .required(validationTexts.REQUIRED),
});

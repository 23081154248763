import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';

import { usePermission } from '@/hooks/usePermissions';
import {
  selectAddMenuItemsModal,
  selectAnotherContextItems,
  selectDefaultMenuItems,
  selectDefaultMobileMenuItems,
  selectDeleteMenuItemId,
  selectEditMenuItem,
  selectFetchingGetMenuItems,
  selectFetchingUpdateMenuItem,
  selectMenuContext,
  selectMenuItems,
  selectMobileMenuItems,
  selectParentId,
} from '@/store/slices/menu/menuItems';
import { selectCurrentMenuType } from '@/store/slices/menu/menuTypes';
import { MenuTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';

export const useMenuState = () => {
  const canUpdate = usePermission('MENU_UPDATE');
  const { menuCode } = useParams();

  const menuAddItemsModal = useSelector(selectAddMenuItemsModal);
  const deleteMenuItemId = useSelector(selectDeleteMenuItemId);
  const anotherContextItems = useSelector(selectAnotherContextItems);
  const menuItems = useSelector(selectMenuItems);
  const mobileMenuItems = useSelector(selectMobileMenuItems);
  const defaultMenuItems = useSelector(selectDefaultMenuItems);
  const defaultMobileMenuItems = useSelector(selectDefaultMobileMenuItems);
  const editMenuItem = useSelector(selectEditMenuItem);
  const menuContext = useSelector(selectMenuContext);
  const parentId = useSelector(selectParentId);
  const currentTypeMenu = useSelector(selectCurrentMenuType);
  const isFetching = useSelector(selectFetchingGetMenuItems);
  const isUpdating = useSelector(selectFetchingUpdateMenuItem);

  const navigate = useNavigate();
  const breadcrumbs = [{ name: 'Меню', url: '/menu' }, { name: 'Управление меню' }];
  const isBlock =
    currentTypeMenu === MenuTypeEnum.MOBILE_MENU
      ? !isEqual(defaultMobileMenuItems, mobileMenuItems)
      : !isEqual(defaultMenuItems, menuItems);
  const { proceed, reset, state } = useBlocker(isBlock);

  const currentParent = useMemo(
    () => menuItems?.find((item) => item.id === parentId),
    [menuItems, parentId],
  );

  return {
    canUpdate,
    menuCode,
    menuAddItemsModal,
    deleteMenuItemId,
    anotherContextItems,
    menuItems,
    mobileMenuItems,
    defaultMenuItems,
    defaultMobileMenuItems,
    editMenuItem,
    menuContext,
    parentId,
    currentTypeMenu,
    isFetching,
    isUpdating,
    navigate,
    breadcrumbs,
    proceed,
    reset,
    state,
    currentParent,
  };
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getConfigByCode } from '../DictionaryItems.helpers';

import { useDictionary } from '@/store/slices/dictionary/dictionary.hooks';

export const useDictionaryItemsFilter = <Dict extends string = string>() => {
  const code = (useParams().code ?? '') as Dict;
  const config = useMemo(() => getConfigByCode<Dict>(code), [code]);
  const { data: dictionary, isLoading } = useDictionary(code);

  return { isLoading, code, dictionary, config };
};

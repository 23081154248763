import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { notifySaveError, notifySaveSuccess } from '@/utils/notify';
import { transliteration } from '@/utils/transliteration';

import { getConfigByCode } from '../DictionaryItems.helpers';

import { DEFAULT_VALUES } from './DictionaryItemForm.constants';
import {
  createFormValidate,
  getCreateSubmitData,
  getFieldsFormValues,
  getUpdateSubmitData,
} from './DictionaryItemForm.helpers';
import { DictionaryItemFormProps, DictionaryItemFormValues } from './DictionaryItemForm.types';

import { useCreateDictionaryItem, useUpdateDictionaryItem } from '@/store/slices/dictionary';

export const useDictionaryItemForm = ({
  dictionary,
  item,
  onSuccessSubmit,
}: DictionaryItemFormProps) => {
  const { code } = dictionary;
  const sessionID = useRef(uuid_v4());

  const [create] = useCreateDictionaryItem();
  const [update] = useUpdateDictionaryItem();
  const [isSubmittig, setSubmitting] = useState(false);

  const config = useMemo(() => getConfigByCode(code), [code]);

  const initialValues = useMemo<DictionaryItemFormValues>(() => {
    const fields = getFieldsFormValues(dictionary, item?.fields ?? config.additionalFieldsDefaults);

    if (!item) return { ...DEFAULT_VALUES, fields };

    return {
      code: item.code,
      name: item.name,
      comment: item.comment ?? '',
      sort: item.sort ?? 0,
      isActive: item.isActive ? 'true' : 'false',
      fields,
    };
  }, [config.additionalFieldsDefaults, dictionary, item]);

  const validate = useMemo(() => createFormValidate(dictionary), [dictionary]);
  const form = useForm({ initialValues, validate });

  const generateCode = useCallback(() => {
    form.setValues((prev) => ({ ...prev, code: transliteration(prev.name ?? '') }));
  }, [form]);

  const isAutogenerateCode = !(item || form.isTouched('code'));

  useEffect(() => {
    if (isAutogenerateCode) {
      form.setFieldValue('code', transliteration(form.values.name));
      form.setTouched((prev) => ({ ...prev, code: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutogenerateCode, form.values.name]);

  const onSubmit = form.onSubmit((vals) => {
    setSubmitting(true);

    const promise = item
      ? getUpdateSubmitData(dictionary, vals, sessionID.current, item).then((params) =>
          update({ code, id: item.id, params }).unwrap(),
        )
      : getCreateSubmitData(dictionary, vals, sessionID.current).then((params) =>
          create({ code, params }).unwrap(),
        );

    promise
      .then((res) => {
        notifySaveSuccess();
        onSuccessSubmit?.();
        return res;
      })
      .catch((err) => {
        notifySaveError();
        throw err;
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return {
    form,
    isSubmittig,
    isAutogenerateCode,
    sessionID: sessionID.current,
    labels: config.labels,

    generateCode,
    onSubmit,
  };
};

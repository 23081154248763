export enum EMenuContainerAddNewItemConstants {
  MENU_NAME = 'name',
  TEXT_BOLD = 'isBold',
  ITEM_TYPE = 'itemType',
  URL = 'url',
  IS_VISIBLE = 'isVisible',
  IS_NO_FOLLOW = 'isNofollow',
  IS_BURGER_BOTTOM = 'isBurgerBottom',
}

export enum EAddNewItemConfirmationTexts {
  TITLE = 'Вы уверены?',
  OUT_TITLE = 'Вы уверены, что хотите выйти?',
  REMOVE = 'Пункт меню и его дочерние пункты будут удалены.',
  REMOVE_FROM_ALL_SITES = 'Пункт меню и его дочерние пункты будут удалены с обоих сайтов (пользовательского и корпоративного)',
  REMOVE_FROM_CORP_SITE = 'Пункт меню и его дочерние пункты перестанут отображаться на корпоративном сайте',
  REMOVE_FROM_USER_SITE = 'Пункт меню и его дочерние пункты перестанут отображаться на пользовательском сайте',
  HIDE_ON_ALL_SITES = 'Пункт меню и его дочерние пункты будут скрыты с обоих сайтах (пользовательском и корпоративном)',
  NOT_SAVE_MENU_SITE = 'У вас есть не сохраненные изменения пунктов меню и его дочерних пунктов (пользовательском и корпоративном)',
}

import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';
import { MobileMenuItem, MobileMenuParentItem } from '@prosv/core/types';
import { isTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.helpers';

export const checkIsCollapsable = <
  Item extends TTransformMenuItem | MobileMenuParentItem | MobileMenuItem,
>(
  items: Item[],
  currentItem: Item,
) => {
  if (isTransformMenuItem(currentItem)) {
    return items.some((menuItem) => menuItem.parentId === currentItem.id);
  } else {
    return !!currentItem.items?.length;
  }
};

export const getChildItems = <
  Item extends TTransformMenuItem | MobileMenuParentItem | MobileMenuItem,
>(
  items: Item[],
  currentItem: Item,
) => {
  if (isTransformMenuItem(currentItem)) {
    return items.filter((menuItem) => menuItem.parentId === currentItem.id);
  } else {
    return currentItem.items || [];
  }
};

import { TRequestUpdateMenuItems } from '@/types';

import { URLS } from '@/api/urls';

import api from '../instance';
import { MobileMenuParentItem } from '@prosv/core/types';

export const apiGetMenuItem = (menuType: string) => api.get(URLS.menus.getMenusByType(menuType));

export const apiGetMobileMenuItem = () => api.get(URLS.menus.getMobileMenu);

export const apiPutMenuItem = (params: TRequestUpdateMenuItems) =>
  api.put(URLS.menus.putMenusByType(params.menuType), params.data);

export const apiPutMobileMenuItem = (data: MobileMenuParentItem[]) =>
  api.put(URLS.menus.putMobileMenu, {
    items: data,
  });

export const tryGetMenuItemsContext = async (menuType: string) => {
  const { data } = await apiGetMenuItem(menuType);
  return data;
};

export const tryGetMobileMenuItem = async () => {
  const { data } = await apiGetMobileMenuItem();
  return data;
};

export const tryPutMenuItem = async (params: TRequestUpdateMenuItems) => {
  const { data } = await apiPutMenuItem(params);
  return data;
};

export const tryPutMobileMenuItem = async (reqData: MobileMenuParentItem[]) => {
  const { data } = await apiPutMobileMenuItem(reqData);
  return data;
};

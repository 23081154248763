import { FC } from 'react';
import { Box, Button, Group, NumberInput, Radio, Text, TextInput } from '@mantine/core';
import { Robot } from 'tabler-icons-react';

import { getFormatDate } from '@/utils/getCurrentDate';

import { AdditionalField } from './AdditionalField';
import { FIELDS } from './DictionaryItemForm.constants';
import { useDictionaryItemForm } from './DictionaryItemForm.hooks';
import { DictionaryItemFormProps } from './DictionaryItemForm.types';

import styles from './DictionaryItemForm.module.scss';

export const DictionaryItemForm: FC<DictionaryItemFormProps> = ({
  item,
  dictionary,
  onSuccessSubmit,
}) => {
  const { form, isSubmittig, isAutogenerateCode, sessionID, labels, onSubmit, generateCode } =
    useDictionaryItemForm({
      item,
      dictionary,
      onSuccessSubmit,
    });

  return (
    <Box className={styles.root}>
      {item && (
        <>
          <Text size='xs' c='dimmed'>
            ID: {item.id}
          </Text>
          <Text pb={8} size='xs' c='dimmed'>
            Дата создания: {getFormatDate(item.createAt.value.toUpperCase())}
          </Text>
        </>
      )}

      <form {...{ onSubmit }}>
        <TextInput
          withAsterisk
          disabled={isSubmittig}
          label={labels.name}
          placeholder={`Введите ${labels.name}`}
          {...form.getInputProps(FIELDS.name)}
        />

        <TextInput
          withAsterisk
          disabled={isSubmittig}
          label={labels.code}
          placeholder={`Введите ${labels.code}`}
          rightSection={
            !isAutogenerateCode && (
              <Button tabIndex={-1} variant='light' size='compact-lg' p='xs' onClick={generateCode}>
                <Robot size={22} />
              </Button>
            )
          }
          {...form.getInputProps(FIELDS.code)}
        />

        <TextInput
          disabled={isSubmittig}
          label={labels.comment}
          placeholder={`Введите ${labels.comment}`}
          {...form.getInputProps(FIELDS.comment)}
        />

        <NumberInput
          disabled={isSubmittig}
          label={labels.sort}
          {...form.getInputProps(FIELDS.sort)}
        />

        {dictionary.isCustom &&
          dictionary.additionalFields.map((field) => (
            <AdditionalField
              key={field.code}
              disabled={isSubmittig}
              data={item?.fields[field.code]}
              {...{ form, field, sessionID }}
            />
          ))}

        <Radio.Group label='Активность' {...form.getInputProps(FIELDS.isActive)}>
          <Group>
            <Radio value='true' label='Да' disabled={isSubmittig} />
            <Radio value='false' label='Нет' disabled={isSubmittig} />
          </Group>
        </Radio.Group>

        <Group justify='flex-end' mt={16}>
          <Button type='submit' disabled={isSubmittig}>
            {item ? 'Сохранить' : 'Создать'}
          </Button>
        </Group>
      </form>
    </Box>
  );
};

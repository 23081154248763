import { FC } from 'react';
import { Button, Group } from '@mantine/core';

interface Props {}

export const SubmitButton: FC<Props> = () => {
  return (
    <Group justify='flex-end' pt={12}>
      <Button type='submit' px={60} fz={16}>
        Подтвердить
      </Button>
    </Group>
  );
};
